import React from 'react'; 
import { useEffect,useState } from 'react';
import { NavLink} from 'react-router-dom';
import '../styles/scss/Sidenav.scss';

import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Scrollbars } from 'react-custom-scrollbars';
import Send from '../images/send.svg';
import Sign from '../images/sign-white.svg';
import { StorageService } from '../services/storageService';
import {isEmpty } from './Utils'

function Sidenav() { 

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const storageService = new StorageService();
        const userinfoData = JSON.parse(storageService.get("userinfo"))
        setUserData(userinfoData)
    
    }, []);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
        } else if (event.key === 'Escape') {
        setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);


    return (
        <div className='sidenav'> 
            <nav>
            <div>
                <Button
                    class="dropdown"
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle} >
                    New Document
                    <ArrowDropDownIcon />
                </Button>
                <Popper
                class="dropdown-menu"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem={open}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={handleListKeyDown}
                            >
                                <MenuItem onClick={handleClose}><img src={Send} alt="send"/> Send for Signature</MenuItem>
                                <MenuItem onClick={handleClose}><img src={Sign} alt="sign"/> Sign yourself</MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
                </Popper>
            </div>
            <Scrollbars style={{ height: "calc(100vh - 175px)"  }}>
                <ul>
                  {/*  <li>
                     <Dropdown as={ButtonGroup}>
                        <Button variant="success" class="dropdown_btn">New Document</Button>
                        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1"><img src={Envelope} alt=""/>  Send for Signature</Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><img src={Envelope} alt=""/> Sign yourselfn</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> 
                    
                    </li>*/}
                    <li className='dashboard'>
                        <NavLink  exact to="/" activeClassName="active">
                            <span></span>
                            <label>Dashboard</label>
                        </NavLink>
                    </li>
                    <li className='documents'>
                        <NavLink to="/documents" activeClassName="active">
                            <span></span>
                            <label>My Documents</label>
                        </NavLink></li>
                     {!isEmpty(userData)&&isEmpty(userData.parentId)&&(<li className='documents'>
                    <NavLink to="/companies" activeClassName="active">
                        <span></span>
                        <label>Companies</label>
                    </NavLink></li>)}
                    <li className='documents'>
                    <NavLink to="/categories" activeClassName="active">
                        <span></span>
                        <label>Categories</label>
                    </NavLink></li>
                    {//!isEmpty(userData)&&isEmpty(userData.parentId)&&(
                        <li className='documents'>
                    <NavLink to="/api" activeClassName="active">
                        <span></span>
                        <label>API's</label>
                    </NavLink></li>
                    //)
                    }
                    <li className='pending'>
                        <NavLink to="/pending-with-me" activeClassName="active">
                            <span></span>
                            <label>Pending with Me</label>
                        </NavLink></li>
                    <li className='others'>
                        <NavLink to="/pending-with-others" activeClassName="active">
                            <span></span>
                            <label>Pending with others</label>
                        </NavLink>
                    </li>
                    <li className='completed'>
                        <NavLink to="/completed" activeClassName="active">
                            <span></span>
                            <label>Completed</label>
                        </NavLink>
                    </li>
                    <li className='drafts'>
                        <NavLink to="/drafts" activeClassName="active">
                            <span></span>
                            <label>Drafts</label>
                        </NavLink>
                    </li>
                    <li className='templates'>
                        <NavLink to="/Templates" activeClassName="active">
                            <span></span>
                            <label>Templates</label>
                        </NavLink>
                    </li>
                    <li className='payments'>
                        <NavLink to="/planAndPayments" activeClassName="active">
                            <span></span>
                            <label>Plan and Payments</label>
                        </NavLink>
                    </li>
                    <li className='reports'>
                        <NavLink to="/reports" activeClassName="active">
                            <span></span>
                            <label>Reports</label>
                        </NavLink>
                    </li>
                    <li className='settings'>
                        <NavLink to="/settings" activeClassName="active">
                            <span></span>
                            <label>Settings</label>
                        </NavLink>
                    </li>
                </ul>
            </Scrollbars>
            <div className='navfooter'>
                <div class="upgrade_btn">Enterprise Trial <span> UPGRADE</span></div>
            </div>
            </nav>

            
        </div>
    )
}


export default Sidenav;