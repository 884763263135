import React, { useState,useEffect } from 'react';
import '../styles/scss/login.scss';
import '../styles/scss/components/simpleInput.scss';
import { EndPointService } from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
import { DateUtilService } from '../services/dateUtilService';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
function ValidateEmail() {
    
    const [value,setValue] = useState("");
    //Get query params
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)

        const history = useHistory();
        const handleResponse = () => {
        // Redirect to Login page
                history.push('/Login');
          };
   useEffect(() => {
      handleClick()
    })
    function handleClick(){
        //JSON format  for payload
        const dateUtilService = new DateUtilService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        console.log("Starting request ...");

         fetchService.post({
            url: endpointService.getVerify(),
            data: {

                    pin : searchParams? searchParams.get('key'): '',
                    action: "verify",
                    userId: searchParams? searchParams.get('userId'): '',
                    timezone: dateUtilService.getTimeZone(),
                    browserTS: dateUtilService.getCurDateTime()
            },
        })
          .subscribe(
                //onNext callback
                (response) => {
                    console.log('Response...', response.result);
                   setValue(response.message)
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                }
            );
    }

    return (
        <div className="login_page_wrapper">
            <div className="login_area">
                <div className="login-form">
                    <form>
                        <div className="form-group">
                        <h4>{value}</h4>       
                        </div>
                        <button type="button" onClick={handleResponse} className="primary_btn login">
                       Back to Login
                        </button>
                     
                    </form>
                </div>
            </div>
        </div>
    )
}
export default ValidateEmail;