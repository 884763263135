import React from 'react';
import { useEffect,useState } from 'react';
import { useForm } from 'react-hook-form';
import '../styles/scss/table.scss';
import '../styles/scss/dialog.scss';
import '../styles/scss/modal.scss';

import { EndPointService } from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
import { DateUtilService } from '../services/dateUtilService';
import { StorageService } from '../services/storageService';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import Close from '../images/x-mark.svg';
import Grid from '@mui/material/Unstable_Grid2';
import NoData from './components/noData'
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '@mui/material/Pagination';
import { TextField } from '@mui/material';
import { showToast, isApiLoading, isEmpty } from './Utils'
import Simpleinput from './components/simpleInput';



const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#aaa"
    }
}));

const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};

function documentsData(name, email, status, date, sign) {
    return { name, email, status, date, sign };
}

const rows = [
    documentsData(' Mobile Project Agreement', 'Madison@Scarlett.com', 'In-Progress', '', 'Sign Now'),
    documentsData(' Joining Agreement', 'Madison@Scarlett.com', 'Completed', '', 'Sign Now'),
    documentsData(' Project Extend Contract', 'Madison@Scarlett.com', 'Cancelled', '12-Dec-2022', '', ''),
    documentsData(' Website Design Agreement', 'Madison@Scarlett.com', 'In-Progress', '12-Dec-2022'),
    documentsData(' NDA Agreement', 'Madison@Scarlett.com', 'Completed', '12-Dec-2022'),
    documentsData(' Mobile Project Agreement', 'Madison@Scarlett.com', 'In-Progress', '12-Dec-2022'),
    documentsData(' Joining Agreement', 'Madison@Scarlett.com', 'Completed', '12-Dec-2022'),
    documentsData(' Project Extend Contract', 'Madison@Scarlett.com', 'Cancelled', '12-Dec-2022'),
    documentsData(' Website Design Agreement', 'Madison@Scarlett.com', 'In-Progress', '12-Dec-2022'),
    documentsData(' NDA Agreement', 'Madison@Scarlett.com', 'Completed', '12-Dec-2022'),
];

function Companies() {
    const [answer, setAnswer] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [showNoData, setShowNoData] = React.useState(true)
    const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, reset: reset1, clearErrors: clearErrors1, control:control1 } = useForm();
    const { register: register2, reset: reset2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, clearErrors: clearErrors2, control: control2 } = useForm({
        mode: "all",
        reValidateMode: "all"
    });

    const formFieldsList = [
        { name: "name", type: "", label: "Company Name", placeholder: "Company Name", required: true, helperText: "", md: 12, xs: 12 },
        { name: "fullName", type: "", label: "Full Name", placeholder: "Full Name", required: "", helperText: "", md: 6, xs: 6 },
        { name: "email", type: "email", label: "Email", placeholder: "Ex: username@domain", required: true, helperText: "", md: 6, xs: 6 },
        { name: "contactEmail", type: "", label: "Contact Email", placeholder: "Contact Email", required: "", helperText: "Optional", md: 6, xs: 6 },
        { name: "website", type: "", label: "Website", placeholder: "Website", required: "", helperText: "", md: 6, xs: 6 },
        { name: "phone", type: "phone", label: "Phone Number", placeholder: "Phone Number", required: true, helperText: "", md: 6, xs: 6 }
    ]

    const updateFormFieldsList = [
        { name: "name", type: "", label: "Company Name", placeholder: "Company Name", required: true, disabled:false,helperText: "", md: 12, xs: 12 },
        { name: "fullName", type: "", label: "Full Name", placeholder: "Full Name", required: "", disabled:false,helperText: "", md: 6, xs: 6 },
        { name: "email", type: "email", label: "Email", placeholder: "Ex: username@domain", required: true, disabled:true,helperText: "", md: 6, xs: 6 },
        { name: "contactEmail", type: "", label: "Contact Email", placeholder: "Contact Email", required: false, disabled:false,helperText: "Optional", md: 6, xs: 6 },
        { name: "website", type: "", label: "Website", placeholder: "Website", required: false, disabled:false,helperText: "", md: 6, xs: 6 },
        { name: "phone", type: "phone", label: "Phone Number", placeholder: "Phone Number", required: true, disabled:false,helperText: "", md: 6, xs: 6 }
    ]

    // const registerOptions = {
    //     name: {
    //         required: {
    //             value: true,
    //             message: "Company name is required."
    //         },
    //         type: 'text'
    //     },
    //     firstName: {
    //         type: "text"
    //     },
    //     lastName: {
    //         type: "text"
    //     },
    //     email: {
    //         required: "Email is required.",
    //         type: "email",
    //         pattern: {
    //             message: "Enter Valid Email Address",
    //             value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    //         }
    //     },
    //     phoneNumber: {
    //         type: "tel",
    //         pattern: {
    //             value: /^[0-9+-]+$/,
    //             message: "Enter Valid Phone Number."
    //         }

    //     }
    // }

    const [formDetails, setFormDetails] = React.useState({
        name: "",
        fullName: "",
        contactEmail: "",
        website: "",
        email: "",
        phoneCode: "+1",
        phone: "",
        phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' }
    })
    const [companiesList, setCompaniesList] = React.useState([])
    const [paginationProps, setPaginationProps] = React.useState({
        count: 1,
    })
    const [filters, setFilters] = React.useState({
        page: 1,
        perpage: 6,
        filters: {
            title: "",
            statusIds: [],
            createdByIds: [],
            createdOnRange: []
        },
        sorting: {
            path: "createdOn", // statusName,createdByName, name, email, phone, createdOn, updatedOn
            order: -1
        }
    })
    const theme = useTheme();
    const [personName] = React.useState([]);
    const [modalOpen, modalActive] = React.useState(false);
    const [modalStatusChangeOpen, modalStatusChangeActive] = React.useState(false)
    const [modalEditOpen, modalEditActive] = React.useState(false);
    const [isUpdateCompany, setIsUpdateCompany] = React.useState(false);
    const [statusChangeData, setStatusChangeData] = React.useState({
        companyId: "",
        statusId: "",
        statusName: ""
    })
    const [userData, setUserData] = useState(null);
    const [companyDetails, setCompanyDetails] = React.useState({
        companyId: "",
        name: "",
        fullName: "",
        email: "",
        contactEmail: "",
        website: "",
        phoneCode: "+1",
        phone: "",
        timezone: "",
        browserTS: "",
        phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' },
    })
    const { LoadingSpinner } = isApiLoading()

    const getAccessToken = () => {
        const storageService = new StorageService();
        return (storageService.get("userinfo") === "undefined") ? "" : JSON.parse(storageService.get("userinfo")).accessToken
    }

    const onChangePage = (e, value) => {
        setFilters((prev) => ({ ...prev, page: value }))
    }

    const getCompaniesList = () => {
        // console.log("request starting for companies list.................")
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getCompaniesListUrl(),
            headers: token,
            data: filters
        })
            .subscribe(
                //onNext callback
                (response) => {
                    setShowNoData(false)
                    setCompaniesList([])
                    if (response.status_code === 200) {
                        let { list, totalCount, perpage } = response.result
                        let count = Math.ceil(totalCount / perpage)
                        setPaginationProps((prev) => ({ ...prev, count }))
                        setCompaniesList(list)
                    }
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    setCompaniesList([])
                    setShowNoData(false)
                }
            );
    }

    const showModal = event => {
        modalActive(current => !current);
    };
    const hideModal = () => modalActive(false);
    const hideEditModal = () =>  setIsUpdateCompany(false);
    const hideStatusChangeModal = () => modalStatusChangeActive(false)

    useEffect(() => {
        getCompaniesList()
    }, [filters, modalOpen, modalEditOpen, modalStatusChangeOpen])
    useEffect(() => {
        const storageService = new StorageService();
        const userinfoData = JSON.parse(storageService.get("userinfo"))
        setUserData(userinfoData)
    
    }, []);

    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === 1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    // const names = [
    //     'All Documents',
    //     'Pdf Documents',
    //     'Agreements',
    //     'Contracts',
    // ];

    //to add new company
    const onClickSave = (data) => {
        // console.log("you clicked on save.", data)
        const dateUtilService = new DateUtilService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        let payLoadData = {
            ...data,
            phone: formDetails.phone,
            phoneCode: formDetails.phoneCode,
            phoneCountryCode: formDetails.phoneCountryCode,
            timezone: dateUtilService.getTimeZone(),
            browserTS: dateUtilService.getCurDateTime()
        }
        fetchService.post({
            url: endpointService.getCreateCompany(),
            data: payLoadData,
            headers: token
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        showToast(response.message, "success")
                        onClickCancel()
                    }
                    else {
                        showToast(response.message, "error")
                    }
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                }
            );
    }

    // upon cancel of add new company form
    const onClickCancel = () => {
        reset1({
            name: "",
            fullName: "",
            contactEmail: "",
            website: "",
            email: "",
            phoneCode: "+1",
            phone: "",
        })
        setFormDetails({
            name: "",
            fullName: "",
            contactEmail: "",
            website: "",
            email: "",
            phoneCode: "+1",
            phone: "",
            phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' }
        })
        clearErrors1()
        hideModal()
    }

    //change status to active or inactive
    const onActiveStatusChange = (e) => {
        e.preventDefault()
        // console.log("you clicked changing company status.")
        const dateUtilService = new DateUtilService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()

        fetchService.post({
            url: endpointService.getCompanyUpdateStatus(),
            data: {
                companyId: statusChangeData.companyId,
                statusId: statusChangeData.statusId,
                timezone: dateUtilService.getTimeZone(),
                browserTS: dateUtilService.getCurDateTime()
            },
            headers: token
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        hideStatusChangeModal()
                        showToast(response.message, "success")
                        getCompaniesList()
                    }
                    else {
                        showToast(response.message, "error")
                    }
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                }
            );
    }

    const getCompanyDetails = (companyId) => {
        //  console.log("requesting company details...", companyId)
        const dateUtilService = new DateUtilService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getCompanyDetailsUrl(),
            data: {
                companyId
            },
            headers: token
        })
            .subscribe(
                //onNext callback
                (response) => {
                    let { result } = response
                    if (response.status_code === 200) {
                        setCompanyDetails({
                            companyId: "",
                            name: "",
                            fullName: "",
                            email: "",
                            contactEmail: "",
                            website: "",
                            phoneCode: "+1",
                            phone: "",
                            phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' },
                            timezone: "",
                            browserTS: "",
                        })
                        const companyDetails = {
                            companyId: result._id ? result._id : "",
                            name: result.name ? result.name : "",
                            fullName: result.fullName ? result.fullName : "",
                            contactEmail: result.contactEmail ? result.contactEmail : "",
                            website: result.website ? result.website : "",
                            email: result.email ? result.email : "",
                            phoneCode: result.phoneCode ? result.phoneCode : "",
                            phone: result.phone&&result.phoneCode ?result.phoneCode+result.phone : "",
                            phoneCountryCode: result.phoneCountryCode ? result.phoneCountryCode : { countryCode: 'US', countryCallingCode: '1' }
                        }
                        setCompanyDetails(companyDetails)
                        reset2(companyDetails)
                        modalEditActive(true)
                        setIsUpdateCompany(true)
                    }
                    else {
                        showToast(response.message, "error")
                    }
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    alert(error)

                }
            );
    }


    //to update company details
    const onUpdateCompanyDetails = (data) => {
        // console.log("you clicked on edit submit ....", data)
        const dateUtilService = new DateUtilService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        const { name, website, contactEmail, phone, fullName, email } = data
        fetchService.post({
            url: endpointService.getUpdateCompany(),
            data: {
                updateBaseDetails: true,
                companyId: companyDetails.companyId,
                name,
                fullName,
                email,
                contactEmail,
                website,
                phone: companyDetails.phone,
                phoneCode: companyDetails.phoneCode,
                phoneCountryCode: companyDetails.phoneCountryCode,
                signDocUrl: "http://localhost:4200/#/auth/sign-document",
                timezone: dateUtilService.getTimeZone(),
                browserTS: dateUtilService.getCurDateTime()
            },
            headers: token
        })
            .subscribe(
                //onNext callback

                (response) => {
                    if (response.status_code === 200) {
                        showToast(response.message, "success")
                        onCancelDetailsUpdate()
                    }
                    else {
                        showToast(response.message, "error")
                        hideEditModal()
                    }
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    alert(error)

                }
            );
    }

    // to cancel update company details 
    const onCancelDetailsUpdate = () => {
        setCompanyDetails({
            companyId: "",
            name: "",
            fullName: "",
            email: "",
            contactEmail: "",
            website: "",
            phoneCode: "+1",
            phone: "",
            phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' },
            timezone: "",
            browserTS: "",
        })
        hideEditModal()
    }

    // to store add new company form details change
    const onChangeFormDetails = (e, data, formattedValue, isPhoneNumber,countryName) => {
        const { name, value } = e.target

        // if (name == "phone") {
        //     setFormDetails((previous) => ({
        //         ...previous,
        //         [name]: value,
        //         phoneCode: data.dialCode
        //     }))
        // }
        if (isPhoneNumber) {
                // let phoneCode = {
                //     countryCode: data.countryCode,
                //     countryCallingCode: data.dialCode
                // }
                // let phoneNumber=!isEmpty(data)&&!isEmpty(data.dialCode)? typeof value === 'string'&&value.slice(data.dialCode.length):""
                // setFormDetails((previous) => ({
                //     ...previous,
                //     ['phoneCode']: data.dialCode,
                //     ['phoneCountryCode']: phoneCode,
                //     ['phone']: phoneNumber,
                // }))
                let phoneCode = {
                    countryCode: data.countryCode,
                    countryCallingCode: data.dialCode
                }
                let phoneNumber=!isEmpty(data)&&!isEmpty(data.dialCode)&& typeof value === 'string'? value.slice(data.dialCode.length):""
                console.log("phoneNumber",phoneNumber)
                setFormDetails((previous) => ({
                    ...previous,
                    ['phoneCode']: data.dialCode,
                    ['phoneCountryCode']: phoneCode,
                    ['phone']: '',
                }))
                setTimeout(() => {
                    setFormDetails((previous) => ({
                        ...previous,
                        ['phone']: phoneNumber,
                    }))
                   // trigger();
                }, 1)
                console.log("inputphone", formDetails)
        }
        else {
            setFormDetails((previous) => ({
                ...previous,
                [name]: value
            }))
            if (value) {
                clearErrors1(name);
            }
        }
    }

    const onChangeEditFormDetails = (e, data, formattedValue, isPhoneNumber,countryName) => {
        const { name, value } = e.target
        if (isPhoneNumber) {
            // let phoneCode = {
            //     countryCode: data.countryCode,
            //     countryCallingCode: data.dialCode
            // }
            // setCompanyDetails((previous) => ({
            //     ...previous,
            //     ['phoneCode']: data.dialCode,
            //     ['phoneCountryCode']: phoneCode,
            //     ['phone']: value,
            // }))
            let phoneCode = {
                countryCode: data.countryCode,
                countryCallingCode: data.dialCode
            }
            let phoneNumber=!isEmpty(data)&&!isEmpty(data.dialCode)&& typeof value === 'string'? value.slice(data.dialCode.length):""
            console.log("editphoneNumber",phoneNumber)
            setCompanyDetails((previous) => ({
                ...previous,
                ['phoneCode']: data.dialCode,
                ['phoneCountryCode']: phoneCode,
                ['phone']: '',
            }))
            setTimeout(() => {
                setCompanyDetails((previous) => ({
                    ...previous,
                    ['phone']: phoneNumber,
                }))
               // trigger();
            }, 1)
            console.log("inputeditphone", formDetails)
    }
        else {
            setCompanyDetails((previous) => ({
                ...previous,
                [name]: value
            }))
            if (value) {
                clearErrors2(name);
            }
        }
    }


    const onEditClick = (companyId) => {
        getCompanyDetails(companyId)
    }

    const onClickChangeStatus = (companyData) => {
        modalStatusChangeActive(true)
        setStatusChangeData({
            companyId: companyData._id,
            statusId: (companyData.statusId === 1) ? 2 : 3,
            statusName: (companyData.statusId === 1) ? "Activate" : "Inactivate"
        })
    }



    // to store search value changes
    const onChangeSearchInput = (e) => {
        const { name, value } = e.target
        setFilters((prev) => ({
            ...prev,
            filters: { ...prev.filters, [name]: value }
        }))
    }

    const renderCompaniesListView = () => {
        return (
            <>
                <div className='body-content'>
                    <div className='eq-table'>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Company Name</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        companiesList.map((row) => (
                                            <TableRow
                                                key={row._id}
                                            >
                                                <TableCell>
                                                    <span>{row.name}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>
                                                        {row.name}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>
                                                        {row.email}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{`+${row.phoneCode&& row.phone? row.phoneCode + row.phone : ""}`}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>{row.statusDetails.name}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <div className='d-inline-flex align-center'>
                                                        <div className='actions-td'>

                                                            <Tooltip enterTouchDelay={0} placement="bottom-end" id="actions-menu" effect="solid"
                                                                arrow
                                                                title={
                                                                    <React.Fragment>
                                                                        <div className='actions-menu'>
                                                                            <ul>
                                                                                <li>
                                                                                    <Link>View</Link>
                                                                                </li>
                                                                                <li onClick={() => onClickChangeStatus(row)} >
                                                                                    <Link>{(row.statusId === 1) ? "Activate" : "Inactivate"}</Link>
                                                                                </li>
                                                                                <li onClick={() => onEditClick(row._id)}>
                                                                                    <Link>Edit</Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link>Delete</Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <span className='actions-icon'></span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>)
                                        )
                                    }
                                </TableBody>
                                {/* <TableBody> */}
                                {/* {rows.map((row) => (
                    <TableRow
                        key={row.name}
                    >
                        <TableCell>
                            <span>{row.name}</span>
                        </TableCell>
                        <TableCell>
                            <span>
                                SY
                            </span>
                        </TableCell>
                        <TableCell>
                            <span>
                                SY@yopmail.com
                            </span>
                        </TableCell>
                        <TableCell>
                            <span>+91 9876543210</span>
                        </TableCell>
                        <TableCell>
                            <div className='d-inline-flex align-center'>
                                <div className='actions-td'>

                                    <Tooltip enterTouchDelay={0} placement="bottom-end" id="actions-menu" effect="solid"
                                        arrow
                                        title={
                                            <React.Fragment>
                                                <div className='actions-menu'>
                                                    <ul>
                                                        <li>
                                                            <Link>View</Link>
                                                        </li>
                                                        <li>
                                                            <Link>Edit</Link>
                                                        </li>
                                                        <li>
                                                            <Link>Delete</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </React.Fragment>
                                        }
                                    >
                                        <span className='actions-icon'></span>
                                    </Tooltip>
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                ))} */}

                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Pagination
                    count={paginationProps.count}
                    page={filters.page}
                    onChange={onChangePage}
                    defaultPage={4}
                    siblingCount={0}
                    boundaryCount={2}
                    size='large'
                    color="secondary"
                    variant="outlined"
                    shape="rounded" />
            </>
        )
    }


    return (
        <React.Fragment>
            <div className='sub-header'>
                <div className='page-title'>
                    <h3>Companies</h3>
                </div>
                <div className='page-filters'>
                    <ul>
                        <li>
                            <div className="search_area">
                                <input type="text" className="form-control" placeholder="Search for email or file name" name="title" onChange={onChangeSearchInput} />
                                {/* <img src="./images/search.png" className="search_icon" alt="search"> */}
                            </div>
                        </li>
                        <li>
                            <button className='primary_btn' onClick={showModal}>Add New</button>
                        </li>
                    </ul>
                </div>
            </div>

            {<NoData
                isLoading={showNoData}
                arrayList={companiesList}
            />}
            {!showNoData && !isEmpty(companiesList) && companiesList.length > 0 && renderCompaniesListView()}

            {/* add company modal */}
            <div className={modalOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>Add Company</h2>
                            <a className="close_btn" onClick={onClickCancel}><img src={Close} alt='close' /></a>
                        </div>
                        <form onSubmit={handleSubmit1(onClickSave)}>
                            <div className='modal-body'>
                                {/* <Grid container xs={12} spacing={1.3}>
                                <Grid md={12} xs={12}>
                                <div className='form-group'>
                                        <TextField
                                            label="Company Name"
                                            hiddenLabel
                                            placeholder="Company Name"
                                            
                                            name="name"
                                        {...register('name',registerOptions.name)}
                                            value={formDetails.name}
                                            helperText={errors?.name && errors.name.message}
                                            onChange={onChangeFormDetails} />
                                            
                                    </div>
                                </Grid>
                                <Grid md={6} xs={6}>
                                    <div className='form-group'>
                                        <TextField
                                         label="First Name" 
                                         hiddenLabel 
                                         placeholder="First Name" 
                                         name="firstName"   
                                         {...register('firstName',registerOptions.firstName)} 
                                         value={formDetails.firstName} 
                                         onChange={onChangeFormDetails} />
                                    </div>
                                </Grid>
                                <Grid md={6} xs={6}>
                                    <div className='form-group'>
                                        <TextField label="Last Name"
                                         hiddenLabel 
                                         placeholder="Last Name" 
                                         name="lastName"   
                                         {...register('lastName',registerOptions.lastName)} 
                                         value={formDetails.lastName} 
                                         onChange={onChangeFormDetails} />
                                    </div>
                                </Grid>
                                <Grid md={6} xs={6}>
                                    <div className='form-group'>
                                        <TextField
                                            label="Email"
                                            hiddenLabel
                                            placeholder="username@domain"
                                            helperText={errors?.email && errors.email.message}
                                            name="email"
                                        {...register('email',registerOptions.email)}
                                            value={formDetails.email}
                                            onChange={onChangeFormDetails} />
                                    </div>
                                </Grid>
                                <Grid md={6} xs={6}>
                                    <div className='form-group'>
                                        <TextField label="Phone Number" 
                                        hiddenLabel 
                                        placeholder="Phone Number" 
                                        name="phone"   
                                        {...register('phone',registerOptions.phone)} 
                                        value={formDetails.phone} 
                                        onChange={onChangeFormDetails} />
                                    </div>
                                </Grid>
                            </Grid> */}
                                <Grid container xs={12} spacing={1.3}>
                                    {formFieldsList.map((field) => (
                                        <Grid md={field.md} xs={field.xs} key={field.name}>
                                            <div className='form-group'>

                                                <Simpleinput
                                                    id={field.name}
                                                    fieldName={field.name}
                                                    label={''}
                                                    hideLabel={true}
                                                    placeHolder={field.placeholder}
                                                    errorText={field.placeholder}
                                                    register={register1}
                                                   // inputValue={formDetails[field.name]}
                                                    onInputChange={onChangeFormDetails}
                                                    errors={errors1}
                                                    type={field.type}
                                                    control={control1}
                                                    isRequired={field.required}
                                                    phoneCountryCode={formDetails.phoneCountryCode}
                                                />
                                            </div>

                                        </Grid>
                                    )
                                    )}
                                </Grid>

                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={onClickCancel} >Cancel</button>
                                <button className="primary_btn active" type='submit'  >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* {isLoading && <LoadingSpinner />} */}
            </div>
            {/* company details update modal */}
            {isUpdateCompany&&<div //className={modalEditOpen ? "custom_modal open" : "custom_modal"}
             className= "custom_modal open">
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>Update Company Details</h2>
                            <a className="close_btn" onClick={hideEditModal}><img src={Close} alt='close' /></a>
                        </div>
                        <form onSubmit={handleSubmit2(onUpdateCompanyDetails)}>
                            <div className='modal-body'>

                                <Grid container xs={12} spacing={1.3}>
                                    {updateFormFieldsList.map((field) => {

                                        return (
                                            <Grid md={field.md} xs={field.xs} key={field.name}>
                                                <div className='form-group'>
                                                    <Simpleinput
                                                        id={field.name}
                                                        fieldName={field.name}
                                                        label={field.label}
                                                        hideLabel={true}
                                                        placeHolder={field.placeholder}
                                                        errorText={field.placeholder}
                                                        register={register2}
                                                        disabled={field.disabled}
                                                        inputValue={companyDetails[field.name]}
                                                        onInputChange={onChangeEditFormDetails}
                                                        errors={errors2}
                                                        type={field.type}
                                                        control={control2}
                                                        isRequired={field.required}
                                                        phoneCountryCode={companyDetails.phoneCountryCode}
                                                    />
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={onCancelDetailsUpdate} >Cancel</button>
                                <button className="primary_btn active" type='submit'  >Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>}
            {/* status change modal */}
            <div className={modalStatusChangeOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>Company Status Change</h2>
                            <a className="close_btn" onClick={hideStatusChangeModal}><img src={Close} alt='close' /></a>
                        </div>
                        <form onSubmit={onActiveStatusChange}>
                            <div className='modal-body'>
                                <p>Click on Confirm to {statusChangeData.statusName}</p>
                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={hideStatusChangeModal} >Cancel</button>
                                <button className="primary_btn active" type='submit'  >Confirm</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}

export default Companies;