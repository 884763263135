import React, { useState, useEffect } from 'react';
//import { ToastContainer, toast } from 'react-toastify'
//import 'react-toastify/dist/ReactToastify.css';
import '../styles/scss/login.scss';
import '../styles/scss/components/simpleInput.scss';
import Envelope from '../images/envelope.svg';
import Padlock from '../images/padlock.svg';
import { EndPointService } from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
import { DateUtilService } from '../services/dateUtilService';
import { StorageService } from '../services/storageService';
import TextField from '@mui/material/TextField';
import Simpleinput from './components/simpleInput';
import { useForm } from "react-hook-form";
import {showToast,isApiLoading} from './Utils'
import Loader from '../images/loader.gif';
import { useHistory, BrowserRouter as Router, Route, Link } from 'react-router-dom';
function Login() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { register, clearErrors, handleSubmit, formState: { errors },  } = useForm();
    const history = useHistory();
    const {  LoadingSpinner } = isApiLoading()

    useEffect(() => {
        document.body.classList.add('login-layout')
        return () => {
            document.body.classList.remove('login-layout')
        }
    }, [])
    const handleEmailInputChange = (event) => {
        setEmail(event.target.value);
        clearErrors("email")
    };
    const handlePasswordInputChange = (event) => {
        setPassword(event.target.value);
        clearErrors("password")
    };
    function onSubmit(data) {
        setIsLoading(true)
        const dateUtilService = new DateUtilService();
        const storageService = new StorageService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        fetchService.post({
            url: endpointService.getLogin(),
            data: {
                "email": email,
                "pwd": password,
                "timezone": dateUtilService.getTimeZone(),
                "browserTS": dateUtilService.getCurDateTime()
            },
        })
            .subscribe(
                (response) => {
                    setIsLoading(false)
                    if (response.status && response.status == 'Success') {
                        storageService.set('userinfo', response.result.data);
                        storageService.set('isLoggedIn', true);
                       // showToast(response.message,"success")
                        setEmail("")
                        setPassword("")
                        history.replace('/') 
                        console.log("UserLoginSuccess")
                    } else {
                        storageService.set('isLoggedIn', false);
                        showToast(response.message,"error")
                        console.log("UserLoginFail")
                    }
                },
                (error) => {
                    console.error("There has been an error", error);
                }
            );


    };
    const onErrors = (errors) => {
        console.log("errors are ", errors)
    }
    return (
        <div className="login_page_wrapper">
            <div className="login_area">
                <h4>Sign In</h4>
                <div className="login-form">
                    <form>
                        <div className="form-group">
                            <img src={Envelope} alt="Envelope" />
                            <Simpleinput
                                id={'email'}
                                fieldName={'email'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"Email Address"}
                                errorText={"Email Address"}
                                register={register}
                                inputValue={email}
                                errors={errors}
                                isRequired={true}
                                type={'email'}
                                onInputChange={handleEmailInputChange} 
                                />
                        </div>
                        <div className="form-group">
                            <img src={Padlock} alt="Padlock" />
                            <Simpleinput
                                id={'password'}
                                fieldName={'password'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"Password"}
                                errorText={"Password"}
                                register={register}
                                inputValue={password}
                                errors={errors}
                                isRequired={true}
                                type={'password'}
                                onInputChange={handlePasswordInputChange}
                            />
                        </div>
                        <div className="forgot_link">
                            <Link to="/forgotpassword">Forgot Password?</Link>
                        </div>
                        <button type="button" onClick={handleSubmit(onSubmit, onErrors)} className="primary_btn login">
                            Login
                        </button>
                        {/* <ToastContainer
                            position="bottom-right"
                            autoClose={3000}
                            hideProgressBar={true}
                            closeOnClick
                            draggable
                        /> */}
                        <div className="sign_up">
                            <p>Don't have an account? <Link to="/signup"> <span>Sign up</span></Link></p>
                        </div>
                    </form>
                </div>
            </div>
            {isLoading&&<LoadingSpinner/>}
        </div>
    )
}
export default Login;