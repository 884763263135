import React from 'react';
import '../styles/scss/table.scss';
import '../styles/scss/dialog.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';  
import Tooltip from '@mui/material/Tooltip';
import { Link} from 'react-router-dom';  


function documentsData(name, owner, status, date,) {
    return { name, owner, status, date, };
}
  
const rows = [
    documentsData(' Mobile Project Agreement', 'Ravi Kumar', 'In-Progress' , '12-Dec-2022'),
    documentsData(' Joining Agreement', 'Ravi', 'Completed' ,'12-Dec-2022'),
    documentsData(' Project Extend Contract', 'Syam', 'Cancelled' ,'12-Dec-2022'),
    documentsData(' Website Design Agreement', 'Ashok', 'In-Progress' ,'12-Dec-2022'),
    documentsData(' NDA Agreement', 'Satish', 'Completed' ,'12-Dec-2022'),
    documentsData(' Mobile Project Agreement', 'Ravi', 'In-Progress' , '12-Dec-2022'), 
];


function Reports() {  
    return (      
        <React.Fragment>  
            <div className='sub-header'>
                <div className='page-title'>
                    <h3>Reports</h3>
                </div>
                <div className='page-filters'>
                    <ul>      
                        <li>
                            <div className="search_area">
                                <input type="text" className="form-control" placeholder="Search for email or file name" />
                                {/* <img src="./images/search.png" className="search_icon" alt="search"> */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body-content'>
                <div className='eq-table'>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Document Name</TableCell>
                                    <TableCell>Owner</TableCell>
                                    <TableCell>Recipients</TableCell>
                                     <TableCell>Status</TableCell> 
                                   <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                <TableRow
                                    key={row.name}                                     
                                    >
                                    <TableCell>
                                        <span>{row.name}</span>
                                    </TableCell>  
                                    <TableCell> 
                                        <span>{row.owner}</span>
                                    </TableCell>                                  
                                    <TableCell> 
                                        <div className='list-elements'>
                                            <ul>
                                                <li className='completed'>                                                    
                                                    <Tooltip arrow placement="top" enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <figure>
                                                            <img src={require("../images/user-img.jpg")} alt="Material"/>
                                                        </figure>
                                                    </Tooltip>
                                                </li>
                                                <li className='completed'>                                                    
                                                    <Tooltip arrow placement="top" enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <label>RK</label>
                                                    </Tooltip>
                                                </li>
                                                <li className='inprogress'>                                                    
                                                    <Tooltip arrow placement="top" enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <label>RK</label>
                                                    </Tooltip>
                                                </li>
                                                <li className='more-elements'>
                                                    
                                                    <Tooltip arrow enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                        <React.Fragment>                                                            
                                                            <ul>
                                                                <li>
                                                                    <figure>
                                                                        <img src={require("../images/user-img.jpg")} alt="Material"/>
                                                                    </figure>
                                                                    <label>Ravi Kumar</label>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        SK
                                                                    </span>
                                                                    <label>Syam Kumar</label>
                                                                </li>
                                                            </ul>
                                                        </React.Fragment>
                                                        }
                                                    >
                                                        <span>+5</span>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <span className={row.status+' es-status'}>{row.status}</span>
                                    </TableCell>
                                        <TableCell>
                                            <div  className='actions-td'>
                                                
                                                <Tooltip  enterTouchDelay={0}  arrow  placement="bottom-end"  id="actions-menu"
                                                    title={
                                                        <React.Fragment>    
                                                            <div className='actions-menu'>
                                                                <ul>
                                                                    <li>
                                                                        <Link>View</Link>                                                                        
                                                                    </li>
                                                                    <li>
                                                                        <Link>Edit</Link>
                                                                    </li>
                                                                    <li>                                                                        
                                                                        <Link>Delete</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                > 
                                                    <span className='actions-icon'></span>
                                                </Tooltip>
                                            </div>
                                        </TableCell> 
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </React.Fragment>        
    )
}

export default Reports;