import React from 'react';
import '../styles/scss/table.scss';
import '../styles/scss/dialog.scss';
import { Link} from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip'; 

function documentsData(name, reqname, status, date, sign) {
    return { name, reqname, status, date, sign};
}
  
const rows = [
    documentsData(' Joining Agreement', 'Me', 'In-Progress','05 Dec 2022', 'Sign Now'),
    documentsData(' Project Extend Contract', 'Joseph', 'In-Progress','10 Dec 2022', 'Sign Now' ),
    documentsData(' Joining Agreement', 'Ravi Kumar', 'In-Progress' ,'05 Dec 2022','', ''),
    documentsData(' Project Extend Contract', 'Me', 'In-Progress' ,'10 Dec 2022'),
];

function pendingWithOthers() { 

    return (      
        <React.Fragment>  
            <div className='sub-header'>
                <div className='page-title'>
                    <h3>Pending with others</h3>
                </div>
                <div className='page-filters'>
                    <ul>       
                        <li>
                            <div className="search_area">
                                <input type="text" className="form-control" placeholder="Search" /> 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body-content'>
                <div className='eq-table checktable'>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                    <div className='flex-label'><Checkbox
                                        color="primary"  defaultChecked 
                                    /> Document Name</div></TableCell>
                                    <TableCell>Requested By</TableCell>
                                    <TableCell>Recipients</TableCell>
                                    <TableCell>Due By</TableCell>
                                     <TableCell>Status</TableCell> 
                                   <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                <TableRow
                                    key={row.name}                                     
                                    >
                                    <TableCell>
                                    <div className='flex-label'><Checkbox
                                        color="primary" 
                                    />
                                        <span>{row.name}</span></div>
                                    </TableCell>  
                                    <TableCell> 
                                        <span>{row.reqname}</span>
                                    </TableCell>                                  
                                    <TableCell> 
                                        <div className='list-elements'>
                                            <ul>
                                                <li className='completed'>                                                    
                                                    <Tooltip arrow placement="top"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <figure>
                                                            <img src={require("../images/user-img.jpg")} alt="Material"/>
                                                        </figure>
                                                    </Tooltip>
                                                </li>
                                                <li className='completed'>                                                    
                                                    <Tooltip arrow placement="top"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <label>RK</label>
                                                    </Tooltip>
                                                </li>
                                                <li className='inprogress'>                                                    
                                                    <Tooltip arrow placement="top"
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <label>RK</label>
                                                    </Tooltip>
                                                </li>
                                                <li className='more-elements'>
                                                    
                                                    <Tooltip arrow
                                                        title={
                                                        <React.Fragment>                                                            
                                                            <ul>
                                                                <li>
                                                                    <figure>
                                                                        <img src={require("../images/user-img.jpg")} alt="Material"/>
                                                                    </figure>
                                                                    <label>Ravi Kumar</label>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        SK
                                                                    </span>
                                                                    <label>Syam Kumar</label>
                                                                </li>
                                                            </ul>
                                                        </React.Fragment>
                                                        }
                                                    >
                                                        <span>+5</span>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </div>
                                    </TableCell>
                                    <TableCell> 
                                        <span>{row.date}</span>
                                    </TableCell> 
                                    <TableCell>
                                        <span className={row.status+' es-status'}>{row.status}</span>
                                    </TableCell>
                                    <TableCell>
                                        <div arrow className='actions-td'>
                                            <Tooltip  enterTouchDelay={0}    arrow  placement="bottom-end"  id="actions-menu"
                                                title={
                                                    <React.Fragment>    
                                                        <div className='actions-menu'>
                                                            <ul>
                                                                <li>
                                                                    <Link>View</Link>                                                                        
                                                                </li>
                                                                <li>
                                                                    <Link>Edit</Link>
                                                                </li>
                                                                <li>                                                                        
                                                                    <Link>Delete</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            > 
                                                <span className='actions-icon'></span>
                                            </Tooltip>
                                        </div>
                                    </TableCell> 
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            <div className="Profile-footer table-footer">
                <button className="primary_btn active">Cancel</button>
                <button className="primary_btn more">Send Reminder</button>
            </div>
        </React.Fragment>        
    )
}

export default pendingWithOthers;