import React from 'react';
import '../styles/scss/table.scss';
import '../styles/scss/dialog.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';  
import Tooltip from '@mui/material/Tooltip';
import { Link} from 'react-router-dom'; 
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: "#aaa"
    }
  }));
  
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

function documentsData(name, email, status, date, sign) {
    return { name, email, status, date, sign};
}
  
const rows = [
    documentsData(' Mobile Project Agreement', 'Madison@Scarlett.com', 'In-Progress','', 'Sign Now'),
    documentsData(' Joining Agreement', 'Madison@Scarlett.com', 'Completed','', 'Sign Now' ),
    documentsData(' Project Extend Contract', 'Madison@Scarlett.com', 'Cancelled' ,'12-Dec-2022','', ''),
    documentsData(' Website Design Agreement', 'Madison@Scarlett.com', 'In-Progress' ,'12-Dec-2022'),
    documentsData(' NDA Agreement', 'Madison@Scarlett.com', 'Completed' ,'12-Dec-2022'),
    documentsData(' Mobile Project Agreement', 'Madison@Scarlett.com', 'In-Progress' , '12-Dec-2022'),
    documentsData(' Joining Agreement', 'Madison@Scarlett.com', 'Completed' ,'12-Dec-2022'),
    documentsData(' Project Extend Contract', 'Madison@Scarlett.com', 'Cancelled' ,'12-Dec-2022'),
    documentsData(' Website Design Agreement', 'Madison@Scarlett.com', 'In-Progress' ,'12-Dec-2022'),
    documentsData(' NDA Agreement', 'Madison@Scarlett.com', 'Completed' ,'12-Dec-2022'),
];


function Documents() { 
    const [answer, setAnswer] = React.useState("");
    const theme = useTheme();
    const [personName] = React.useState([]);

  
    function getStyles(name, personName, theme) {
        return {
          fontWeight:
            personName.indexOf(name) === 1
              ? theme.typography.fontWeightRegular
              : theme.typography.fontWeightMedium,
        };
    }

    const names = [
        'All Documents',
        'Pdf Documents',
        'Agreements',
        'Contracts', 
      ];

  
    return (      
        <React.Fragment>  
            <div className='sub-header'>
                <div className='page-title'>
                    <h3>My Documents</h3>
                </div>
                <div className='page-filters'>
                    <ul>  
                        <li>
                        <div className='form-group mb-0'> 
                            <FormControl sx={{ m: 1, width: 300 }}>                                 
                                <Select  
                                type="text"
                                name="select"
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                placeholder={"Search Country...."}
                                defaultValue="All Documents"
                                value={answer}
                                onChange={event => setAnswer(event.target.value)}
                                renderValue={
                                    answer !== "" ? undefined : () => <Placeholder>Select</Placeholder>
                                }
                                displayEmpty
                                input={<OutlinedInput label="Name" />} 
                                >
                                {/* <MenuItem value={10}>All Documents</MenuItem>
                                <MenuItem value={20}>Pdf Documents</MenuItem>
                                <MenuItem value={20}>Agreements</MenuItem>
                                <MenuItem value={20}>Contracts</MenuItem> */}
                                {names.map((name) => (
                                    <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, personName, theme)}
                                    >
                                    {name}
                                    </MenuItem>
                                ))}
                                </Select>
                            </FormControl>
                        </div>
                        </li>
                        <li>
                            <div className="search_area">
                                <input type="text" className="form-control" placeholder="Search for email or file name" />
                                {/* <img src="./images/search.png" className="search_icon" alt="search"> */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body-content'>
                <div className='eq-table'>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Document Name</TableCell>
                                    <TableCell>Me</TableCell>
                                    <TableCell>Recipients</TableCell>
                                     <TableCell>Status</TableCell> 
                                   <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                <TableRow
                                    key={row.name}                                     
                                    >
                                    <TableCell>
                                        <span>{row.name}</span>
                                    </TableCell>  
                                    <TableCell> 
                                        <div className='user-icon'>
                                            <span>
                                                SY
                                            </span>
                                        </div>
                                    </TableCell>                                  
                                    <TableCell> 
                                        <div className='list-elements'>
                                            <ul>
                                                <li className='completed'>                                                    
                                                    <Tooltip arrow placement="top" enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <figure>
                                                            <img src={require("../images/user-img.jpg")} alt="Material"/>
                                                        </figure>
                                                    </Tooltip>
                                                </li>
                                                <li className='completed'>                                                    
                                                    <Tooltip arrow placement="top" enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <label>RK</label>
                                                    </Tooltip>
                                                </li>
                                                <li className='inprogress'>                                                    
                                                    <Tooltip arrow placement="top" enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='usericon-details'>
                                                                    <label>Ravi Kumar</label>
                                                                    <p>ravikumar@innvectra.com</p>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <label>RK</label>
                                                    </Tooltip>
                                                </li>
                                                <li className='more-elements'>
                                                    
                                                    <Tooltip arrow enterTouchDelay={1} leaveTouchDelay={5000}
                                                        title={
                                                        <React.Fragment>                                                            
                                                            <ul>
                                                                <li>
                                                                    <figure>
                                                                        <img src={require("../images/user-img.jpg")} alt="Material"/>
                                                                    </figure>
                                                                    <label>Ravi Kumar</label>
                                                                </li>
                                                                <li>
                                                                    <span>
                                                                        SK
                                                                    </span>
                                                                    <label>Syam Kumar</label>
                                                                </li>
                                                            </ul>
                                                        </React.Fragment>
                                                        }
                                                    >
                                                        <span>+5</span>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <span className={row.status+' es-status'}>{row.status}</span>
                                    </TableCell>
                                        <TableCell>
                                            <div className='d-inline-flex align-center'>
                                                <button className=' primary_btn'>Sign Now</button> 
                                                <div  className='actions-td'>
                                                    
                                                    <Tooltip  enterTouchDelay={0} placement="bottom-end"  id="actions-menu" effect="solid"
                                                    arrow
                                                        title={
                                                            <React.Fragment>    
                                                                <div className='actions-menu'>
                                                                    <ul>
                                                                        <li>
                                                                            <Link>View</Link>                                                                        
                                                                        </li>
                                                                        <li>
                                                                            <Link>Edit</Link>
                                                                        </li>
                                                                        <li>                                                                        
                                                                            <Link>Delete</Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    > 
                                                        <span className='actions-icon'></span>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        </TableCell> 
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </React.Fragment>        
    )
}

export default Documents;