import moment from 'moment';


export class DateUtilService {

    /**
     * Get timeZone
     * @returns Sting
     */
    getTimeZone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    /**
     * Get Current date time by Timezone
     * @returns String
     */
    getCurDateTime() {
        return moment().format();
    }

    getFormattedDate(date) {
        return moment(date).format('DD MMM YYYY')
    }
    
}