import React ,{ useState,useEffect} from 'react'; 
//import { ToastContainer, toast } from 'react-toastify'
import '../styles/scss/forgotpassword.scss';  
import '../styles/scss/components/simpleInput.scss';
import Envelope from '../images/envelope.svg';
import { EndPointService }  from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
//import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Simpleinput from './components/simpleInput';
import TextField from '@mui/material/TextField';
import { useForm } from "react-hook-form";
import {showToast} from './Utils'
import Loader from '../images/loader.gif';
//import { Link} from 'react-router-dom';

function ForgotPassword() {
    const [email, setEmail] = useState("");
    const [isApiLoading, setIsApiLoading] = useState(false);
    useEffect(() => {
        document.body.classList.add('login-layout')
        return () => {
            document.body.classList.remove('login-layout')
        }
    }, [])
    //const history = useHistory();
    const { register, clearErrors, handleSubmit, formState: { errors }, } = useForm();
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        clearErrors('')
      };
    function onSubmit(data) {
       // alert(JSON.stringify(data))
       setIsApiLoading(true)
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        fetchService.post({
             url:endpointService.getForgotPassword(),
             data: data
         })
         .subscribe(
             (response) => {
                setIsApiLoading(false)
                 //console.log('Response...', response.result);
                 if (response.status == 'Success') {
                   showToast(response.message,"success") 
                 }else{
                    showToast(response.message,"error")
                 }
             },
             (error) => {
                 console.error("There has been an error", error);
             }
         );
    };
    const onErrors = (errors) => {
        console.log("errors are ", errors)
    }
    return (
        <div className="forgot_page_wrapper"> 
            <div className="forgot_area">
                <h4>Forgot Password</h4>
                <div className="forgot-form">
                    <form>
                        <div className="form-group">
                            <img src={Envelope} alt="Envelope" />
                            <Simpleinput
                                id={'email'}
                                fieldName={'email'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"Email Address"}
                                errorText={"Email Address"}
                                register={register}
                                inputValue={email}
                                errors={errors}
                                isRequired={true}
                                type={'email'}
                                onInputChange={handleEmailChange}
                                 />
                        </div>
                        <button type="button" className="primary_btn login" onClick={handleSubmit(onSubmit, onErrors)}>
                            Submit
                        </button>
                        {/* <ToastContainer
                                position="bottom-right"
                                autoClose={3000}
                                hideProgressBar={true}
                                closeOnClick
                                draggable
                        /> */}
                    </form>
                </div>
            </div>
            {isApiLoading&&
            (<div>
                <span className='loader'>
                <img src={Loader} alt='Loader' />
            </span>
            </div>)} 
        </div>
    )
}


export default ForgotPassword;

