import React, { useState,useEffect,useRef } from 'react'; 
import Grid from '@mui/material/Grid';
import { DropzoneArea } from 'material-ui-dropzone';
import { AttachFile } from '@material-ui/icons';
import{isEmpty} from "./Utils";
import { EndPointService } from '../services/endPointServices';
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../images/loader.gif';
import { Controller,useForm } from 'react-hook-form';
import { StorageService } from '../services/storageService';
import TextField from '@mui/material/TextField';
function UploadFileComponent({label,className,showPreviews,filesLimit,dropzoneText,Icon,showPreviewsInDropzone,useChipsForPreview,showFileNamesInPreview,acceptedFiles,
    previewGridProps,register,errorText,fieldName,errors,handleSelectedFile,id,isRequired,control,isValidationRequired,clearErrors,selectedFile,handleDelete}) {
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const dropzoneRef = useRef(id)
    useEffect(() => {
        const storageService = new StorageService();
        const userinfoData=JSON.parse(storageService.get("userinfo"))
        setUserData(userinfoData)
        
      },[]);

    function uploadFiles(file){
     console.log("file",file)
        setUploadedFiles([...uploadedFiles, ...file])
        clearErrors(fieldName)
        setIsLoading(true)
        const endpointService = new EndPointService();
            const formData = new FormData();
          file.forEach((fileItem) => {
            formData.append('file', fileItem);
          });
          formData.append('secureType', 'private'); 
        fetch(endpointService.getUpload(), {
            method: 'POST',
            headers: {
                'Authorization':'Bearer ' + userData.accessToken,
            },
            body:formData
        })
            .then(response => response.json())
            .then((responseJson) => {
                setIsLoading(false)
                if(responseJson.status==="Success"){
                    handleSelectedFile(responseJson.result)
                }
            })
            .catch((error) => {
                console.error("ErrorMessage",error);
            });
        
    }
    // const handleDelete = (removedFile) => {
    //   const updatedFiles = uploadedFiles.filter((file, index) => file !== removedFile);
    //   setUploadedFiles(updatedFiles);
    
    // };
    const validateFiles=(file)=> {
        if (file.length == 0) {
          return errorText + ' is required';
        }
        return true;
      }

    return (
        <div>   
                <label>{label}</label>
                <div className='file_upload_wrapper'>
                 <Controller
                        control={control}
                        name={fieldName}
                        variant="outlined"
                        rules={{ required: isValidationRequired?errorText + ' is reqired':false}}
                        render={({ field }) => (
                            <DropzoneArea
                            {...field}
                            ref={dropzoneRef}
                            className={className}
                            showPreviews={showPreviews}
                            filesLimit={filesLimit}
                            dropzoneText={dropzoneText}
                            Icon={Icon}
                            showPreviewsInDropzone={showPreviewsInDropzone}
                            showFileNamesInPreview={showFileNamesInPreview}
                            previewGridProps={previewGridProps}
                            useChipsForPreview={useChipsForPreview}
                            onDrop={(file)=>uploadFiles(file)}
                            id={id} />
                        )}
                    />
                     <DropzoneArea
                     ref={dropzoneRef}
                      className={className}
                      showPreviews={showPreviews}
                      filesLimit={filesLimit}
                      dropzoneText={dropzoneText}
                      acceptedFiles={acceptedFiles}
                      Icon={Icon}
                      initialFiles={selectedFile}
                      showPreviewsInDropzone={showPreviewsInDropzone}
                      showFileNamesInPreview={showFileNamesInPreview}
                      previewGridProps={previewGridProps}
                      useChipsForPreview={useChipsForPreview}
                      onDrop={(file)=>uploadFiles(file)}
                      id={id}
                      onDelete={handleDelete}
                      />      
                   <TextField
                      name={fieldName}
                      variant="outlined"
                      disabled={true}
                      style={{  display: 'none', }}
                     // {...register(fieldName, { required: isValidationRequired, validate: () => validateFiles(uploadedFiles), })}
                      {...register(fieldName, {
                        required: isValidationRequired&&selectedFile.length==0?errorText + ' is required':false,
                       // validate: () => isValidationRequired&&selectedFile.length==0 ? validateFiles(uploadedFiles): true, 
                      })}
                      />
                  {errors&&errors[fieldName] && <span className='form_error'>{errors[fieldName].message}</span>}
                  {isLoading&&(<div>
                    <span className='loader'>
                    <img src={Loader} alt='Loader' />
                </span>
                </div>)}  
                </div>
                 
        </div>
    )
}
export default UploadFileComponent;