import React from 'react';
import '../../styles/scss/components/simpleInput.scss';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Controller } from 'react-hook-form';
import parsePhoneNumber from 'libphonenumber-js';
import { checkProperty, isEmpty } from '../Utils';


function Simpleinput({ label = "", fieldName = "", id = "", isRequired = false, hideLabel = false, onInputChange, placeHolder, errorText,
    inputValue, register, errors, type = "", phoneCountryCode = {
        countryCode: 'US',
        countryCallingCode: '1'
    }, control, ref ,countryName,disabled}) {

    const handlePhoneNumberChange = (value, data, event, formattedValue) => {
        onInputChange(event, data, formattedValue, true,countryName)
        validatePhoneNumber(value)
    };
    const validatePhoneNumber = (value) => {
        try {
            if (value) {
                const phoneNumber = parsePhoneNumber(value, phoneCountryCode.countryCode.toUpperCase());
                if (!phoneNumber || !phoneNumber.isValid()) {
                    return false;
                }
            }
        } catch (error) {
            console.log("parsePhoneNumberFromString ", error)
            return false;
        }
        return true;
    };
    const validatePassword = (value) => {
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasSpecialCharacter = /[!@#$%^&*()_+]/.test(value);
        const hasNumber = /\d/.test(value);
        if (!(hasUppercase)) {
            return errorText + ' must contain at least one uppercase letter.'
        }
        if (!(hasLowercase)) {
            return errorText + ' must contain at least one lowercase letter.';
        }
        if (!(hasSpecialCharacter)) {
            return errorText + ' must contain at least one special character.';
        }
        if (!(hasNumber)) {
            return errorText + ' must contain at least one number.';
        }
        return true;
    };
    return (
        <div>
            <div>
                {type != 'phone' && type != 'email' && type != 'password' &&
                    <TextField
                        // id={fieldName+id }

                        // ref={ref}
                        name={fieldName}
                        label={label ? label : ''}
                        hiddenLabel={hideLabel ? hideLabel : ''}
                        variant="outlined"
                        error={errors?.[fieldName] ? true : false}
                        //inputRef={fieldName+id }
                        placeholder={placeHolder ? (placeHolder + (isRequired && "*")) : ''}
                        // defaultValue={inputValue ? inputValue : ''}
                        type={type}

                        {...register(fieldName, { required: isRequired })}
                        onChange={onInputChange}
                        value={inputValue}
                    />}
                {type == 'email' &&
                    <TextField
                        // id={fieldName+id }
                        name={fieldName}
                        label={label ? label : ''}
                        hiddenLabel={hideLabel ? hideLabel : ''}
                        variant="outlined"
                        error={errors?.[fieldName] ? true : false}
                        //inputRef={fieldName+id }
                        placeholder={placeHolder ? (placeHolder + (isRequired && "*")) : ''}
                        // helperText={errors?.[fieldName] ?errorText+' is reqired':""}
                        disabled={disabled}
                        defaultValue={inputValue ? inputValue : ''}
                        type={type}
                        value={inputValue}
                        {...register(fieldName, {
                            required: isRequired, pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Invalid email address',
                            },
                        })}
                        onChange={onInputChange}
                    />}
                {type == 'password' &&
                    <TextField
                        name={fieldName}
                        label={label ? label : ''}
                        hiddenLabel={hideLabel ? hideLabel : ''}
                        variant="outlined"
                        error={errors?.[fieldName] ? true : false}
                        placeholder={placeHolder ? (placeHolder + (isRequired && "*")) : ''}
                        defaultValue={inputValue ? inputValue : ''}
                        type={type}
                        // helperText={errors[fieldName]?.type === 'validate'? errors[fieldName].message: ''}
                        value={inputValue}
                        inputProps={{ maxLength: 10, }}
                        {...register(fieldName, { required: isRequired, validate: validatePassword, })}
                        onChange={onInputChange}
                    />}
                {type == 'phone' &&
                    <Controller
                        control={control}
                        name={fieldName}
                        variant="outlined"
                        //value={inputValue}
                        rules={{ required: isRequired, validate: validatePhoneNumber, }}
                        // onChange={onInputChange}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                placeholder={placeHolder ? (placeHolder + (isRequired && "*")) : ''}
                                country={phoneCountryCode.countryCode.toLowerCase()}
                                onChange={(value, data, event, formattedValue,countryName) => {
                                    onChange(value)
                                    handlePhoneNumberChange(value, data, event, formattedValue,countryName)
                                }
                                }

                                id={id + fieldName}
                                value={inputValue}
                                inputProps={{
                                    name: fieldName
                                }}
                                countryCodeEditable={false}
                                jumpCursorToEnd={true}
                                prefix={''}
                            // isValid={validatePhoneNumber}
                            />
                        )}

                    />


                }
            </div>


            {errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 1 &&
                checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
                checkProperty(errors[fieldName.split('.')[0]], fieldName.split('.')[1], 'type') === "required" &&
                <span className='form_error'>{errorText + ' is reqired'}</span>}

            {type !== 'password' && errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 1 &&
                checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
                checkProperty(errors[fieldName.split('.')[0]], fieldName.split('.')[1], 'type') === "validate" &&
                <span className='form_error'>{errorText + ' not valid'}</span>}

            {type !== 'password' && errors && fieldName && checkProperty(fieldName.split('.'), 'length') > 1 &&
                checkProperty(errors, fieldName.split('.')[0], fieldName.split('.')[1]) &&
                checkProperty(errors[fieldName.split('.')[0]], fieldName.split('.')[1], 'type') === "pattern" &&
                <span className='form_error'>{errorText + ' not valid'}</span>}



            {errors?.[fieldName] && errors?.[fieldName].type && errors?.[fieldName].type === "required" &&
                <span className='form_error'>{errorText + ' is reqired'}</span>}
            {type !== 'password' && errors?.[fieldName] && errors?.[fieldName].type && errors?.[fieldName].type === "validate" &&
                <span className='form_error'>{errorText + ' not valid'}</span>
            }
            {errors?.[fieldName] && errors?.[fieldName].type && errors?.[fieldName].type === "pattern" &&
                <span className='form_error'>{errors?.[fieldName].message}</span>
            }
            {type === 'password' && errors[fieldName] && (
                <span className='form_error'>{errors[fieldName].message}</span>
            )}
        </div>
    )
}
export default Simpleinput;