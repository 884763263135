import React, { useState, useEffect } from 'react'
import { BrowserRouter, useLocation, useHistory } from 'react-router-dom';

import Header from './views/Header';
import Sidenav from './views/Sidenav';
import Routers from './routes/Router';
import './styles/scss/globalstyles.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




function App() {
  // const location = useLocation();
  // let location = useHistory();


  // useEffect(() => {

    

  //  // setCurrPath(window.location.pathname)


  //   const handleLocationChange = () => {
  //     setCurrPath(window.location.pathname);
  //     alert(window.location.pathname)
  //   };

  //   // Add an event listener for the 'popstate' event
  //   window.addEventListener('popstate', handleLocationChange);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     window.removeEventListener('popstate', handleLocationChange);
  //   };

  // }, [])


  // useEffect(() => {
  //   alert(window.location.pathname)
  // }, [location]);


  return (
    <React.Fragment>
      <BrowserRouter>

        {/* <div className='wrapper'>
          {!isAuthRoutes(currPath) && <Header />}
          <div className='bodylayout'>
             {currPath !== '/login' && <><Sidenav /></>}
            {!isAuthRoutes(currPath) && <Sidenav />}
            <div className='maincontent'> */}
              <Routers />
            {/* </div>
          </div>
        </div> */}
      </BrowserRouter>
      <ToastContainer
      // position="bottom-right"
      // autoClose={3000}
      // hideProgressBar={true}
      // closeOnClick
      // draggable
      />
    </React.Fragment>
  );
}

export default App;
