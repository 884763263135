import React, { useState,useEffect } from 'react';
import '../styles/scss/Header.scss';
import '../styles/scss/templates.scss';
import '../styles/scss/settings.scss';
import Close from '../images/x-mark.svg';
import Simpleinput from './components/simpleInput';
import Padlock from '../images/padlock.svg';
import { useForm } from "react-hook-form";
import {showToast,isApiLoading, isEmpty} from './Utils'
import { EndPointService }  from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
import { useLocation } from 'react-router-dom';
import { StorageService } from '../services/storageService';

function PasswordChangePopup({isOpen,onClose}) {
   
    const [passwordChange, setPasswordChange] = useState({
       currentPassword:"",newPassword:"",conformPassword:""
    });
    const [userData, setUserData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const {  LoadingSpinner } = isApiLoading()
    const { register, clearErrors, handleSubmit, formState: { errors }, } = useForm();
    useEffect(() => {
        const storageService = new StorageService();
        const userinfoData=JSON.parse(storageService.get("userinfo"))
        setUserData(userinfoData)
        
      },[]);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setPasswordChange((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        if (value) {
            clearErrors(name);
        }

    };
    function onClosePopup(){
        onClose(false)
        clearErrors()
        setPasswordChange({
            currentPassword: '',
            newPassword: '',
            conformPassword: '',
          });
    }
    function onSubmit(data) {
        setIsLoading(true)
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        // data.pin=searchParams? searchParams.get('key'): ''
        // data.action="forgot-password"
        // data.companyId=userData.companyId
        fetchService.post({
             url: endpointService.getResetPassword(),
             data:{
                "pin": !isEmpty(searchParams)? searchParams.get('key'): '',
                "action": "forgot-password",
               // "newPwd": passwordChange.currentPassword,
                "newPwd": passwordChange.newPassword,
                "confirmPwd": passwordChange.conformPassword,
                "companyId": userData.companyId
            } 
             //data
         })
         .subscribe(
             (response) => {
                 setIsLoading(false)
                 if (response.status === 'Success') {
                     showToast(response.message,"success")
                     onClosePopup()
                 } else {
                     showToast(response.message,"error")
                 }
             },
             (error) => {
                 console.error("There has been an error", error);
             }
         );

    };
    const onErrors = (errors) => {
        console.log("errors are ", errors)
    }
    return (
        <div className={isOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog change_pwd_dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>Change Password</h2>
                            <a className="close_btn" onClick={onClosePopup} ><img src={Close} alt='close' /></a>
                        </div>
                        <form>
                        <div className='modal-body'>
                        
                        <div className="form-group password_area">
                            <img src={Padlock} alt="Padlock" />
                            <Simpleinput
                                id={'currentPwd'}
                                fieldName={'currentPassword'}
                                label={'Current Password'}
                                hideLabel={true}
                                placeHolder={"Current Password"}
                                errorText={"Current Password"}
                                register={register}
                                inputValue={passwordChange.currentPassword}
                                errors={errors}
                                isRequired={true}
                                type={'password'}
                                onInputChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group password_area">
                            <img src={Padlock} alt="Padlock" />
                            <Simpleinput
                                id={'newPwd'}
                                fieldName={'newPassword'}
                                label={'New Password'}
                                hideLabel={true}
                                placeHolder={"New Password"}
                                errorText={"New Password"}
                                register={register}
                                inputValue={passwordChange.newPassword}
                                errors={errors}
                                isRequired={true}
                                type={'password'}
                                onInputChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group password_area">
                            <img src={Padlock} alt="Padlock" />
                            <Simpleinput
                                id={'ConfirmPwd'}
                                fieldName={'conformPassword'}
                                label={'Conform Password'}
                                hideLabel={true}
                                placeHolder={"Conform Password"}
                                errorText={"Conform Password"}
                                register={register}
                                inputValue={passwordChange.conformPassword}
                                errors={errors}
                                isRequired={true}
                                type={'password'}
                                onInputChange={handleInputChange}
                            />
                        </div>
                        </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={onClosePopup} >Cancel</button>
                                <button className="primary_btn active" type='submit' onClick={handleSubmit(onSubmit, onErrors)} >Save</button>
                            </div>
                        </form>
                        {isLoading&&<LoadingSpinner/>}
                    </div>
                </div>
            </div>
    )
}


export default PasswordChangePopup;