import React from 'react';
import '../styles/scss/table.scss';
import '../styles/scss/dialog.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions'; 
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

function createData(name, email, date,) {
    return { name, email, date, };
  }
  
  const rows = [
    createData(' Madison Scarlett', 'Madison@Scarlett.com', '12-Dec-2022'),
    createData(' Madison Scarlett', 'Madison@Scarlett.com', '12-Dec-2022'),
    createData(' Madison Scarlett', 'Madison@Scarlett.com', '12-Dec-2022'),
    createData(' Madison Scarlett', 'Madison@Scarlett.com', '12-Dec-2022'),
    createData(' Madison Scarlett', 'Madison@Scarlett.com', '12-Dec-2022'),
  ];

function Documents() {
    const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
   

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };
  

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


    return (      
        <React.Fragment>  
            <div className='sub-header'>
                <div className='page-title'>
                    <h2>Contacts</h2>
                </div>
                <div className='page-filters'>
                    <ul>
                        <li>
                            <Button className='primary_btn' onClick={handleClickOpen('body')}>Add Document</Button>
                        </li>
                        <li>
                            {/* <div className="dropdown">
                                <button className="dropdown-toggle filter_btn" type="button" id="dropdownMenuClickable" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
                                    <img src="images/filter.svg"> <span>All Documents</span>
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuClickable">
                                    <li>Documents 1</li>
                                    <li>Documents 2</li>
                                    <li>Documents 3</li>
                                </ul>
                                </div> */}
                        </li>
                        <li>
                            <div className="search_area">
                                <input type="text" className="form-control" placeholder="Search for email or file name" />
                                {/* <img src="./images/search.png" className="search_icon" alt="search"> */}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body-content'>
                <div className='eq-table'>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                    <Checkbox
                                        color="primary"
                                        // indeterminate={numSelected > 0 && numSelected < rowCount}
                                        // checked={rowCount > 0 && numSelected === rowCount}
                                        // onChange={onSelectAllClick}
                                        // inputProps={{
                                        // 'aria-label': 'select all desserts',
                                        // }}
                                    />
                                    </TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Created On</TableCell> 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                        color="primary"
                                        //checked={isItemSelected}
                                        // inputProps={{
                                        //     'aria-labelledby': labelId,
                                        // }}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.date}</TableCell> 
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
            
            
           
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth='md'
            >
                
                <div className="dialog-header">
                    <DialogTitle id="scroll-dialog-title">Add Contact</DialogTitle>
                    <div className='dialog-header-rt'>
                            
                    </div>
                </div>
                <div className="dialog-content">
                        <div className="form_container">
                            <form>
                                <div className="form-group group-box">
                                    <TextField id="outlined-basic" hiddenLabel variant="outlined" placeholder="Name" />
                                    <TextField id="outlined-basic" hiddenLabel variant="outlined" placeholder="Email" />
                                </div>
                                <div className="form-group group-box">
                                    <TextField id="outlined-basic" hiddenLabel variant="outlined" placeholder="Name" />
                                    <TextField id="outlined-basic" hiddenLabel variant="outlined" placeholder="Email" />
                                </div>
                                
                                 
                            </form>
                        </div>
                    </div>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleClose}>Subscribe</Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
        
    )
}

export default Documents;