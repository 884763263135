import React from 'react';
import '../../styles/scss/components/noData.scss';
import { isEmpty } from '../Utils';

function NoData(props) {
    return (
        <div class="nodataTemplate">
            {props.isLoading?<div class="accordian-table">
                <table class="tg">
                    <tr>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                        <th class="tg-cly1">
                            <div class="line"></div>
                        </th>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                        <div class="line"></div>
                        </td>
                        <td class="tg-cly1">
                            <div class="line"></div>
                        </td>
                    </tr>
                </table>
            </div>:!props.isLoading&&props.arrayList.length==0&&<h2 className='no_data'>No Data Found</h2>}
        </div>
    )
}

export default NoData;