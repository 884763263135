import { Observable } from "rxjs";

export class FetchService {
    /**
     * Get Headers
     * @param token | String
     */

    getHeaders({ token, multiPart = false }) {
        let headers = {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }

        if (token) {
            headers = Object.assign(headers, {
                'Authorization': 'Bearer ' + token
            });
        }
        if (multiPart) {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        return headers;
    }

    /**
     *  Fetch with GET Method
     * @param url | String
     * @param data | Object
     * @param headers | Object
     */
    get = ({ headers, url, data }) => {
        return new Observable.create((observer) => {
            console.log(data);
            fetch(url, {
                method: 'GET',
                headers: this.getHeaders({ headers: headers }),
                body: JSON.stringify(data),
            })
                .then((response) =>
                    response.json())
                .then((data) => {
                    observer.next(data);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                    observer.complete();
                });

        });
    }

    /**
     *  Fetch with post Method
     * @param url | String
     * @param data | Object
     * @param headers | Object
     */
    post = ({ headers, url, data }) => {
        return new Observable.create((observer) => {
            console.log(data);
            console.log(url);
            console.log(headers);
            fetch(url, {
                method: 'POST',
                headers: this.getHeaders({ token: headers }),
                body: JSON.stringify(data),
            })
                .then((response) =>
                    response.json())
                .then((data) => {
                    observer.next(data);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                    observer.complete();
                });

        });
        // storageService.set("user-Info",data);
    }

    /**
     *  Fetch with PUT Method
     * @param url | String
     * @param data | Object
     * @param headers | Object
     */
    put = ({ headers, url, data }) => {
        return new Observable.create((observer) => {
            console.log(data);
            fetch(url, {
                method: 'PUT',
                headers: this.getHeaders({ headers: headers }),
                body: JSON.stringify(data),
            })
                .then((response) =>
                    response.json())
                .then((data) => {
                    observer.next(data);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                    observer.complete();
                });

        });
    }

    /**
     *  Fetch with DELETE Method
     * @param url | String
     * @param data | Object
     * @param headers | object
     */
    delete = ({ headers, url, data }) => {
        return new Observable.create((observer) => {
            console.log(data);
            fetch(url, {
                method: 'DELETE',
                headers: this.getHeaders({ headers: headers }),
                body: JSON.stringify(data),
            })
                .then((response) =>
                    response.json())
                .then((data) => {
                    observer.next(data);
                    observer.complete();
                })
                .catch((error) => {
                    observer.error(error);
                    observer.complete();
                });

        });
    }
}

