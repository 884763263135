import {  toast } from 'react-toastify'
//import 'react-toastify/dist/ReactToastify.css';
import Loader from '../images/loader.gif';
export function isEmpty(value) {
    if (value !== undefined && value !== null) {
        if (typeof (value) == 'string') {
            if (value.toString().trim() !== '' && value.toString().trim() !== 'null' && value.toString().trim() !== "") {
                return false
            }
        } else {
            if (value !== '' && value !== 'null' && value !== "")
                return false;
        }
    }
    return true;
}
export function showToast(message,type) {
  switch (type) {
    case "success":
      toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
            theme: "colored",
          });
      break;
    case "error":
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });;
      break;
    default:
      toast(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
      });;
      break;
  }
}
export function isApiLoading(){
  const LoadingSpinner = () => {
    return (
      <div>
        <span className='loader'>
          <img src={Loader} alt='Loader' />
        </span>
      </div>
    );
  };

  return { LoadingSpinner };
}


export function checkProperty(object = null, mainKey = '', subKey = '', returnValue = '') {
  //alert(object)
  if (object != null && mainKey != '') {
    if (object.hasOwnProperty(mainKey)) {
      if (subKey != '') {
        if (object[mainKey].hasOwnProperty( subKey)) {
          returnValue = object[mainKey][subKey];
          if (subKey == 'countryCallingCode' && returnValue) {

            if (!(returnValue.includes("+"))) {
              returnValue = "+" + returnValue
            }
          }
          return returnValue;
        } else {
          return returnValue;
        }
      } else {
        return object[mainKey];
      }
    } else {
      return returnValue;
    }
  } else {
    return returnValue;
  }
}