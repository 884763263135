import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { EndPointService } from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
import { DateUtilService } from '../services/dateUtilService';
import { StorageService } from '../services/storageService';

import NoData from './components/noData';
import {  showToast, isApiLoading ,isEmpty } from './Utils'
import Close from '../images/x-mark.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from "@material-ui/core/styles";
import SimpleSelect from "./components/simpleselect"

const getApiKeyStyle = {
    "display": "block",
    "text-overflow": "ellipsis",
    "width": "200px",
    "overflow": "hidden",
    "white-space": "nowrap"
}

function APIpage() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [showNoData, setShowNoData] = React.useState(true)
    const [modalNewApiKeyOpen, setModalNewApiKey] = React.useState(false)
    const [modalApiKeyInactivateOpen, setModalApiKeyInactivateOpen] = React.useState(false)
    const [companiesList, setCompaniesList] = React.useState([])
    const [companyId, setCompanyId] = React.useState("")
    const [inactivateApikeyData, setInactivateApiKeyData] = React.useState({
        apiKeyId: "",
        uuid: ""
    })
    const [manageKeyAccessData, setManageKeyAccessData] = React.useState({
        apiKeyId: "",
        uuid: "",
        action: "",
        companyIds: [],
        showModal: false,
        showCompanyDropDown: false,
        companiesWithAccess: [],
        manageAccessCompanyList: []
    })
    const [apiKeyfilters, setApiKeyfilters] = React.useState({
        page: 1,
        perpage: 6,
        filters: {
            title: "",
            statusIds: [],
            createdByIds: [],
            createdOnRange: []
        },
        sorting: {
            path: "createdOn", // statusName,createdByName, name, email, phone, createdOn, updatedOn
            order: -1
        }
    })
    const [apiKeysList, setApiKeysList] = React.useState([])
    const [filters, setFilters] = React.useState({
        page: 1,
        perpage: 500,
        filters: {
            title: "",
            statusIds: [],
            createdByIds: [],
            createdOnRange: []
        },
        sorting: {
            path: "createdOn", // statusName,createdByName, name, email, phone, createdOn, updatedOn
            order: -1
        },
        masterData: true
    })
    const { register, handleSubmit, formState: { errors }, clearErrors } = useForm({
        mode: "onChange"
    });
    const registerOption = {
        companyName: {
            required: {
                value: true,
                message: "Please Select a Company."
            },
            type: 'text'
        },
        action: {
            required: {
                value: true,
                message: "Please Select an action"
            },
            type: "text"
        }
    }
    const [paginationProps, setPaginationProps] = React.useState({
        count: 1,
    })
    const actionList = [
        {
            _id: "REMOVE_ACCESS",
            name: "Remove Access"
        },
        {
            _id: "ADD_ACCESS",
            name: "Add Access"
        }
    ]

    useEffect(() => {
        getApiKeyList()
    }, [apiKeyfilters, modalNewApiKeyOpen, modalApiKeyInactivateOpen])

    const getAccessToken = () => {
        const storageService = new StorageService();
        return (storageService.get("userinfo") === "undefined") ? "" : JSON.parse(storageService.get("userinfo")).accessToken
    }

    // to get companies list 
    const getCompaniesList = () => {
        // console.log("request starting for companies list.................")
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getCompaniesListUrl(),
            headers: token,
            data: filters
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        //console.log("list of companies response....", response.result)
                        let { list, totalCount, perpage } = response.result
                        // let count = Math.ceil(totalCount / perpage)
                        // setPaginationProps((prev) => ({ ...prev, count }))
                        let initialCompany = list[0]
                        // console.log("initialCompany  ",initialCompany)
                        setCompaniesList(list)
                    }
                    else {
                        showToast(response.message, "error")
                        setCompaniesList([])
                    }

                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    setCompaniesList([])
                }
            );
    }

    //to create new api key 
    const createNewApiKey = () => {
        // console.log("request starting for create api key   .................")
        const endpointService = new EndPointService();
        const dateUtilService = new DateUtilService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getCreateApiKeyUrl(),
            headers: token,
            data: {
                companyId,
                timezone: dateUtilService.getTimeZone(),
                browserTS: dateUtilService.getCurDateTime()
            }
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        showToast(response.message, "success")
                        setCompanyId("")
                        setModalNewApiKey(false)
                    }
                    else {
                        showToast(response.message, "error")
                    }

                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);

                }
            );
    }

    const createApiKeyCancel = () => {
        clearErrors()
        setCompanyId("")
        setModalNewApiKey(false)
    }

    // to get a apikey details 
    const getApiKeyDetails = () => {
        // console.log("request starting api key details   .................")
        const endpointService = new EndPointService();
        const dateUtilService = new DateUtilService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getApiKeyDetailsUrl(),
            headers: token,
            data: {
                apiKeyId: "64b12e21c463304a20888ee1"
            }
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        showToast(response.message, "success")
                    }
                    else {
                        showToast(response.message, "error")
                    }

                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);

                }
            );
    }

    //to get list of api keys
    const getApiKeyList = () => {
        //console.log("request starting for API KEY LIST.................")
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getApiKeyListUrl(),
            headers: token,
            data: apiKeyfilters
        })
            .subscribe(
                //onNext callback
                (response) => {
                    setShowNoData(false)
                    setApiKeysList([])
                    if (response.status_code === 200) {
                        //console.log("list of API KEYS response....", response.result)
                        let { list, totalCount, perpage } = response.result
                        let count = Math.ceil(totalCount / perpage)
                        setPaginationProps((prev) => ({ ...prev, count }))
                        // if (totalCount) {
                        //     setShowNoData(false)
                            setApiKeysList(list)

                        // }
                        // else {
                        //     setShowNoData(true)
                        // }
                    }
                    else {
                        showToast(response.message,"error")
                        setShowNoData(true)
                    }

                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    setShowNoData(false)
                    setApiKeysList([])
                }
            );
    }

    //to change Inactivate the api key 
    const onConfirmInactivateApiKey = (e) => {
        e.preventDefault()
        // console.log("request starting for onClickInactivate.................")
        const endpointService = new EndPointService();
        const dateUtilService = new DateUtilService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getApiKeyUpdateStatusUrl(),
            headers: token,
            data: {
                ...inactivateApikeyData,
                timezone: dateUtilService.getTimeZone(),
                browserTS: dateUtilService.getCurDateTime()
            }
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        showToast(response.message, "success")
                        setModalApiKeyInactivateOpen(false)
                        setInactivateApiKeyData({
                            apiKeyId: "",
                            uuid: ""
                        })
                    }
                    else {
                        showToast(response.message, "error")
                    }

                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                }
            );

    }

    // to manage the api key access 
    const manageApiKeyAccess = () => {
        const endpointService = new EndPointService()
        const fetchService = new FetchService()
        let token = getAccessToken()
        let { apiKeyId, uuid, action, companyIds } = manageKeyAccessData
        const options = {
            url: endpointService.getManageApiKeyAccessUrl(),
            headers: token,
            data: {
                apiKeyId,
                uuid,
                action,
                companyIds
            }
        }
        fetchService.post(options)
            .subscribe(
                //onNext callback
                (response) => {
                    // console.log("MANAGE API KEYS response....", response.result)
                    if (response.status_code === 200) {
                        showToast(response.message, "success")
                        onManageApiKeyAccessCancel()

                    }
                    else {
                        let { message, error } = response.result.error_message

                    }
                    getApiKeyList()
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                }
            );

    }

    const onClickInactivateApiKey = (apiKeyId, uuid) => {
        setInactivateApiKeyData({
            apiKeyId,
            uuid
        })
        setModalApiKeyInactivateOpen(true)
    }

    const onClickManageOption = (apiKeyId, uuid, list) => {
        getCompaniesList()
        setManageKeyAccessData((prev) => ({
            ...prev,
            apiKeyId,
            uuid,
            showModal: true,
            companiesWithAccess: list
        }))
    }

    const onClickAddNewApi = () => {
        getCompaniesList()
        setModalNewApiKey(true)
    }

    // to store search value changes
    const onChangeSearchInput = (e) => {
        const { name, value } = e.target

        setApiKeyfilters((prev) => ({
            ...prev,
            filters: { ...prev.filters, [name]: value }
        }))
    }

    const onChangePage = (e, value) => {
        // console.log("page changed to ", value)
        setApiKeyfilters((prev) => ({ ...prev, page: value }))
    }

    const onSelectCompanyIds = (e) => {
        // console.log("selected company ids are   ********** ", e.target.value)
        setManageKeyAccessData((prev) => ({
            ...prev,
            companyIds: e.target.value
        }
        ))
        if(e.target.value){
            clearErrors("companyName")
           }
    }

    const onManageApiKeyAccessCancel = () => {
        setManageKeyAccessData({
            apiKeyId: "",
            uuid: "",
            action: "",
            companyIds: [],
            showModal: false,
            showCompanyDropDown: false,
            companiesWithAccess: [],
            manageAccessCompanyList: []
        })

    }

    const onSelectingAction = (e) => {
        const act = e.target.value
        let mlist = []
        if (act === 'ADD_ACCESS') {
            mlist = companiesList
        }
        else {
            mlist = companiesList.filter((company) => {
                let i = manageKeyAccessData.companiesWithAccess.indexOf(company._id)
                if (i >= 0) {
                    return true
                }
                return false
            })
        }

        if (mlist) {
            setManageKeyAccessData((prev) => ({
                ...prev,
                action: act,
                companyIds: [],
                showCompanyDropDown: true,
                manageAccessCompanyList: mlist
            }))
        }
    }


    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#aaa"
        }
    }));

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };

    // jsx to show apikeys list
    const renderApiKeysListView = () => {

        return (
            <>
                <div className='body-content'>
                    <div className='eq-table'>
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Company Name</TableCell>
                                        <TableCell>Api Key</TableCell>
                                        <TableCell>Created on</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        apiKeysList.map((row) => {
                                            const dateUtilService = new DateUtilService()
                                            const { name, _id } = row.companyDetails ? row.companyDetails : ""
                                            const createdOn = dateUtilService.getFormattedDate(row.createdOn)

                                            return (<TableRow
                                                key={row._id}
                                            >
                                                <TableCell>
                                                    <span>{name}</span>
                                                </TableCell>
                                                <TableCell>
                                                    <span style={getApiKeyStyle}>
                                                        {row.apiKey}
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <span>
                                                        {createdOn}
                                                    </span>
                                                </TableCell>

                                                <TableCell>
                                                    <div className='d-inline-flex align-center'>
                                                        <div className='actions-td'>

                                                            <Tooltip enterTouchDelay={0} placement="bottom-end" id="actions-menu" effect="solid"
                                                                arrow
                                                                title={
                                                                    <React.Fragment>
                                                                        <div className='actions-menu'>
                                                                            <ul>
                                                                                <li onClick={() => onClickManageOption(row._id, row.uuid, row.companyIds)}>
                                                                                    <Link>Manage Access</Link>
                                                                                </li>
                                                                                <li onClick={() => onClickInactivateApiKey(row._id, row.uuid)}>
                                                                                    <Link>Inactivate</Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <span className='actions-icon'></span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>)
                                        }
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
                <Pagination
                    count={paginationProps.count}
                    page={apiKeyfilters.page}
                    onChange={onChangePage}
                    defaultPage={4} 
                    siblingCount={0} 
                    boundaryCount={2}
                    size='large'
                    color="secondary"
                    variant="outlined"
                    shape="rounded" />
            </>
        )
    }

    const renderManageApiKeyView = () => {
        return (
            <div className={manageKeyAccessData.showModal ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>Manage API Key Access</h2>
                            <a className="close_btn" onClick={onManageApiKeyAccessCancel}><img src={Close} alt='close' /></a>
                        </div>
                        <form onSubmit={handleSubmit(manageApiKeyAccess)}>
                            <div className='modal-body'>
                                <div className='form-group'>
                                    <SimpleSelect
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        label="Selct an Action"
                                        placeholder='Actions List'
                                        options={actionList}
                                        onInputChange={(e) => {
                                            onSelectingAction(e)
                                           if(e.target.value){
                                            clearErrors("action")
                                           }
                                        }}
                                        inputValue={manageKeyAccessData.action}
                                        errors={errors}
                                        register={register}
                                        fieldName="action"
                                        isrequired={true}
                                        multiple={false}
                                        errorText="An Action "
                                    />
                                </div>
                               
                                {
                                    manageKeyAccessData.showCompanyDropDown && (
                                        <div className='form-group'>
                                            <SimpleSelect
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                label="Company List"
                                                placeholder='Companies List'
                                                options={manageKeyAccessData.manageAccessCompanyList}
                                                onInputChange={onSelectCompanyIds}
                                                inputValue={manageKeyAccessData.companyIds}
                                                errors={errors}
                                                register={register}
                                                fieldName="companyName"
                                                isrequired={true}
                                                multiple={true}
                                                errorText="Company"
                                            />
                                        </div>
                                    )
                                }

                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={onManageApiKeyAccessCancel} >Cancel</button>
                                <button className="primary_btn active" type='submit'  >Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <React.Fragment>
            <div className='sub-header'>
                <div className='page-title'>
                    <h3>API's</h3>
                </div>
                <div className='page-filters'>
                    <ul>
                        <li>
                            <div className="search_area">
                                <input type="text" className="form-control" placeholder="Search for email or file name" name="title" onChange={onChangeSearchInput} />
                            </div>
                        </li>
                        <li>
                            <button className='primary_btn' onClick={onClickAddNewApi}>Add New</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body-content'>
                <div className='eq-table'>
                    {<NoData
                        isLoading={showNoData}
                        arrayList={apiKeysList}
                    />}
                    {!showNoData && !isEmpty(apiKeysList) && apiKeysList.length > 0 && renderApiKeysListView()}

                </div>
            </div>
            <div className={modalNewApiKeyOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>Add New Api Key</h2>
                            <a className="close_btn" onClick={createApiKeyCancel}><img src={Close} alt='close' /></a>
                        </div>
                        <form onSubmit={handleSubmit(createNewApiKey)}>
                            <div className='modal-body'>
                            <div className='form-group'>
                                            <SimpleSelect
                                                labelId="demo-multiple-name-label"
                                                id="demo-multiple-name"
                                                label="Company"
                                                placeholder='Companies List'
                                                options={companiesList}
                                                onInputChange={(e) => {
                                                     setCompanyId(e.target.value)
                                                     clearErrors("companyName")
                                                    }}
                                                inputValue={companyId}
                                                errors={errors}
                                                register={register}
                                                fieldName="companyName"
                                                isrequired={true}
                                                multiple={false}
                                                errorText="Company"
                                            />
                                        </div>
                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={createApiKeyCancel} >Cancel</button>
                                <button className="primary_btn active" type='submit'  >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* api key status change modal */}
            <div className={modalApiKeyInactivateOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>API Key Status Change</h2>
                            <a className="close_btn" onClick={() => setModalApiKeyInactivateOpen(false)}><img src={Close} alt='close' /></a>
                        </div>
                        <form onSubmit={onConfirmInactivateApiKey}>
                            <div className='modal-body'>
                                <p>Click on Confirm to Inactivate API key</p>
                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={() => setModalApiKeyInactivateOpen(false)} >Cancel</button>
                                <button className="primary_btn active" type='submit'  >Confirm</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {manageKeyAccessData.showModal && renderManageApiKeyView()}
          
        </React.Fragment>
    )
}

export default APIpage;