import React from 'react';

function Tasks() {
    return (
        <div className='box'>
            
                <span>Title</span>


        </div>
    )
}

export default Tasks;