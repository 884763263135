import React, { useState } from 'react';
import '../styles/scss/templates.scss';
import '../styles/scss/settings.scss';
import { NavLink} from 'react-router-dom'; 
import upload from '../images/undraw_add_files.svg';
import Close from '../images/x-mark.svg';
import SideNav from 'react-simple-sidenav';
import { Scrollbars } from 'react-custom-scrollbars';




function Templates() {
    const [showNav, setShowNav] = useState();
    return (      
        <div>
                <div className='sub-header'>
                    <div className='page-title'>
                        <h3>Templates</h3>
                    </div>
                    <div className='page-filters'>
                        <ul> 
                            <li>
                                <div className="search_area">
                                    <input type="text" className="form-control" placeholder="Search" /> 
                                </div>
                            </li> 
                        </ul>
                    </div>
                </div>
                <div class="body-content">
                    <div class="upload-template">
                    <NavLink to="/mytemplates"><img src={upload} alt='upload'/></NavLink>
                        <p>Templates are documents that you set up to quickly reuse and send to different people. Uploading your first template is quick and easy. Give it a try!</p>
                        <button className="primary_btn document_btn"  onClick={() => setShowNav(true)}>Upload Your First Template</button>
                    
                        <p>Need a starter contract template?  <NavLink to="#"> Download and customize one from here.</NavLink></p>
                    </div>
                </div>
            <div className='close'>
                <SideNav
                    openFromRight={true}
                    showNav={showNav}
                    onHideNav={() => setShowNav(false)}
                    title="Hello World"
                    titleStyle={{ backgroundColor: '#4CAF50' }}
                    itemStyle={{ backgroundColor: '#fff' }}
                    itemHoverStyle={{ backgroundColor: '#CDDC39' }}
                >
                    <div className='sidenav-title'>
                        <h2>Common Templates</h2>
                        <button className="close_btn"  onClick={() => setShowNav(false)}><img src={Close} alt='close'/></button>
                    </div>
                    
                    <Scrollbars style={{ width: 390, height: "calc(100vh - 50px)" }}>
                        <ul>
                            <li><NavLink to="#">Marketing Agreement</NavLink></li>
                            <li><NavLink to="#">Simple Web Design Contract</NavLink></li>
                            <li><NavLink to="#">Painting Contract Template</NavLink></li> 
                            <li><NavLink to="#">Coaching Contract </NavLink></li> 
                            <li><NavLink to="#">Photography Release Form</NavLink></li> 
                            <li><NavLink to="#">Graphic Design Contract</NavLink></li>
                            <li><NavLink to="#">Commission Agreement </NavLink></li> 
                            <li><NavLink to="#">Event Planning Contract </NavLink></li> 
                            <li><NavLink to="#">Independent Contractor Agreement </NavLink></li> 
                            <li><NavLink to="#">Non Disclosure Agreement Template </NavLink></li> 
                            <li><NavLink to="#">Remodeling Contract</NavLink></li> 
                            <li><NavLink to="#">Retainer Agreement </NavLink></li> 
                            <li><NavLink to="#">Service Contract Template </NavLink></li> 
                            <li><NavLink to="#">Subcontractor Agreement </NavLink></li> 
                            <li><NavLink to="#">Sublease Agreement </NavLink></li> 
                            <li><NavLink to="#">Sales Contract Template </NavLink></li> 
                            <li><NavLink to="#">Agency Agreement Template </NavLink></li> 
                            <li><NavLink to="#">Basic Rental Agreement </NavLink></li> 
                            <li><NavLink to="#">Bookkeeping Contract Template </NavLink></li> 
                            <li><NavLink to="#">Catering Contract </NavLink></li> 
                            <li><NavLink to="#">Construction Contract </NavLink></li> 
                            <li><NavLink to="#">Consulting Agreement </NavLink></li> 
                            <li><NavLink to="#">DJ Contract </NavLink></li> 
                            <li><NavLink to="#">Employment Contract </NavLink></li> 
                            <li><NavLink to="#">Accounting Contract</NavLink></li>
                        </ul>
                    </Scrollbars>
                </SideNav>
            </div>
        </div>
    )
}

export default Templates;