
export class EndPointService  {

    constructor() {
        this.baseUrl = process.env.NODE_ENV === 'production' ? "https://app2.estrokes.com/api/" : "http://localhost:3000/";
    }
    /**
     *  Get login link
    */
    getLogin = () => {
       //return this.baseUrl + "auth/login";
       return this.baseUrl + "companies/login";
    }
    /**
     *Get Forgot-Password Link
     */
    getForgotPassword = () => {
        //return this.baseUrl + "auth/forgot-password";
        return this.baseUrl + "companies/forgot-password";
    }
    /**
     *  Get Reset-Password Link
     */
     getResetPassword = () => {
        //return this.baseUrl + "auth/update-password";
        return this.baseUrl + "companies/update-password";
    }
    getUserUpdate = () => {
        return this.baseUrl + "users/update";
    }
    getUpload = () => {
        return this.baseUrl + "s3/upload";
    }

    getCreateCompany = () => {
        return this.baseUrl + "companies/create";
    }

    getCompaniesListUrl = () => {
        return this.baseUrl + "companies/list"
    }
    /**
     * Get signup link
     */
    companyRegister = () => {
        //return this.baseUrl + "companies/create";
        return this.baseUrl + "companies/create-company";
     }

     /**
      * Get Verify Link
      */
     getVerify = () =>{
        return this.baseUrl + "auth/validate-email"
     }
     /**
      * 
    Get category 
      */
    getCreateCategory= () => {
        return this.baseUrl + "category/create";
    }

    getCategoryList = () => {
        return this.baseUrl + "category/list"
    }
    getCategoryUpdateStatus = () =>
   {
    return this.baseUrl + "category/update-status"
   }
   getCategoryDetails = () =>
   {
    return this.baseUrl + "category/details"
   }
   getCategoryDetailsUpdate = () =>
   {
     return this.baseUrl + "category/update-details"
   }

    getCompanyDetailsUrl = () => {
        return this.baseUrl + "companies/details"
    }

    getUpdateCompany = () => {
        return this.baseUrl + "companies/update"
    }

    getCompanyUpdateStatus = () => {
        return this.baseUrl + "companies/update-status"
    }

    getCreateApiKeyUrl = () => {
        return this.baseUrl + "api_key/create"
    }

    getApiKeyListUrl = () => {
        return this.baseUrl + "api_key/list"
    }

    getApiKeyUpdateStatusUrl = () => {
        return this.baseUrl + "api_key/update-status"
    }

    getApiKeyDetailsUrl = () => {
        return this.baseUrl + "api_key/details" 
    }

    getManageApiKeyAccessUrl = () => {
        return this.baseUrl + "api_key/manage-key-access"
    }

    getTemplateCreateUrl = () => {
        return this.baseUrl + "templates/create"
    }

    getFieldsListUrl = () => {
        return this.baseUrl + "masterdata/list"
    }

    getTemplateListUrl = () => {
        return this.baseUrl + "templates/list"
    }

    getTemplateDetailsUrl = () => {
        return this.baseUrl + "templates/details"
    }

    getTemplateUpdateUrl = () => {
        return this.baseUrl + "templates/update"
    }

    getTemplateStatuUpdateUrl = () => {
        return this.baseUrl + "templates/update-status"
    }

    getTemplateGeneratePdfUrl = () => {
        return this.baseUrl + "templates/generate-pdf"
    }

    getUserDetails = () => {
        return this.baseUrl + "users/details"
    }
}
   

