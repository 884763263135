import React from 'react';
import '../styles/scss/dashboard.scss';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import { Scrollbars } from 'react-custom-scrollbars';


function Dashboard() {
    return (
        <div>
            <div className='dashboard-wrapper'>
                <ul className='pending-list'>
                    <li className='pending-list-item pwm'>
                        <h6>Pending with Me <em className='count'>5</em></h6>
                        <span></span>
                    </li>
                    <li className='pending-list-item pwo'>
                        <h6>Pending with Others <em className='count'>4</em></h6>
                        <span></span>
                    </li>
                    <li className='pending-list-item cfd'>
                        <h6>Continue from Draft <em className='count'>16</em></h6>
                        <span></span>
                    </li>
                </ul>
                <div className='create-new-wrapper'>
                    <span></span>
                    <p>Creating your first document is quick and easy. Give it a try!</p>
                    <div className='sign-btns'>
                        <Link to="/send-for-signature" className='primary_btn' type='button'>Send for Signature</Link>
                        <Link className='primary_btn' type='button'>Sign Yourself</Link>
                    </div>
                </div>
                <div className='pendingList-sec'>
                    <div className='list-header'>
                        <h6>Pending with Me</h6>
                        <Link to="" className='view_all_link'>View All</Link>
                    </div>
                    <div className='list-items'>
                        <ul>
                            <li>
                                <h6>
                                    Mobile Project Agreement
                                    <span>Sent By <em>Ravi Kumar</em></span>
                                </h6>
                                <div className='list-right'>
                                    <span className='due-days'>Due in 5 days</span>
                                    <Link className='primary_btn' type='button'>Sign Now</Link>
                                    <span className='actions-icon'></span>
                                </div>
                            </li>
                            <li>
                                <h6>
                                    Project Extend Contract
                                    <span>Sent By <em>Me</em></span>
                                </h6>
                                <div className='list-right'>
                                    <span className='due-days overdue'>Overdue 5 days</span>
                                    <Link className='primary_btn' type='button'>Sign Now</Link>
                                    <span className='actions-icon'></span>
                                </div>
                            </li>
                            <li>
                                <h6>
                                    NDA Agreement
                                    <span>Sent By <em>Joseph</em></span>
                                </h6>
                                <div className='list-right'>
                                    <span className='due-days'>Due in 5 days</span>
                                    <Link className='primary_btn' type='button'>Sign Now</Link>
                                    <span className='actions-icon'></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='pendingList-sec'>
                    <div className='list-header'>
                        <h6>Pending with others</h6>
                        <Link to="" className='view_all_link'>View All</Link>
                    </div>
                    <div className='list-items'>
                        <ul>
                            <li>
                                <h6>
                                    Mobile Project Agreement
                                    <span>Sent By <em>Ravi Kumar</em></span>
                                </h6>
                                <div className='list-right'>
                                    <span className='due-days'>Due in 5 days</span>
                                    <span className='waiting_list'>
                                        <Tooltip enterTouchDelay={0} arrow  placement="bottom-end"  id="actions-menu"
                                            title={
                                                <React.Fragment>
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                MK
                                                            </span>
                                                            <label>Madhu Kumar</label>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                JP
                                                            </span>
                                                            <label>Joseph</label>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                RK
                                                            </span>
                                                            <label>Ravi Kumar</label>
                                                        </li>
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        >
                                            <label>Waiting for 2 Signers</label>
                                        </Tooltip>
                                    </span>
                                    <span className='actions-icon'></span>
                                </div>
                            </li>
                            <li>
                                <h6>
                                    Project Extend Contract
                                    <span>Sent By <em>Me</em></span>
                                </h6>
                                <div className='list-right'>
                                    <span className='due-days overdue'>Overdue 5 days</span>
                                    <span className='waiting_list'>
                                        <Tooltip enterTouchDelay={0} arrow placement="top"
                                            title={
                                                <React.Fragment>
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                MK
                                                            </span>
                                                            <label>Madhu Kumar</label>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                JP
                                                            </span>
                                                            <label>Joseph</label>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                RK
                                                            </span>
                                                            <label>Ravi Kumar</label>
                                                        </li>
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        >
                                            <label>Waiting for 2 Signers</label>
                                        </Tooltip>
                                    </span>
                                    <span className='actions-icon'></span>
                                </div>
                            </li>
                            <li>
                                <h6>
                                    NDA Agreement
                                    <span>Sent By <em>Joseph</em></span>
                                </h6>
                                <div className='list-right'>
                                    <span className='due-days'>Due in 5 days</span>
                                    <span className='waiting_list'>
                                        <Tooltip enterTouchDelay={0} arrow placement="top"
                                            title={
                                                <React.Fragment>
                                                    <ul>
                                                        <li>
                                                            <span>
                                                                MK
                                                            </span>
                                                            <label>Madhu Kumar</label>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                JP
                                                            </span>
                                                            <label>Joseph</label>
                                                        </li>
                                                        <li>
                                                            <span>
                                                                RK
                                                            </span>
                                                            <label>Ravi Kumar</label>
                                                        </li>
                                                    </ul>
                                                </React.Fragment>
                                            }
                                        >
                                            <label>Waiting for 2 Signers</label>
                                        </Tooltip>
                                    </span>
                                    <span className='actions-icon'></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='right-side-nav'>
                <h6>Recent Activiy</h6>
                <Scrollbars style={{ height: "calc(100vh - 135px)"  }} className='active-scroll'>
                    <ul className='activity-list'>
                        <li className='activity-list-item'>
                            <span className='avatar'>RK</span>
                            <div className='details'>
                                <p>Ravi Kumar <span>viewed the</span></p>
                                <Link>Mobile Project Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                        <li className='activity-list-item'>
                            <span className='avatar'>JP</span>
                            <div className='details'>
                                <p>Joseph <span>commented on</span></p>
                                <Link>NDA Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                        <li className='activity-list-item'>
                            <span className='avatar'>MK</span>
                            <div className='details'>
                                <p>Madhu Kumar <span>signed the</span></p>
                                <Link>Website Design Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                        <li className='activity-list-item'>
                            <span className='avatar'>RK</span>
                            <div className='details'>
                                <p>Ravi Kumar <span>viewed the</span></p>
                                <Link>Mobile Project Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                        <li className='activity-list-item'>
                            <span className='avatar'>JP</span>
                            <div className='details'>
                                <p>Joseph <span>commented on</span></p>
                                <Link>NDA Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                        <li className='activity-list-item'>
                            <span className='avatar'>MK</span>
                            <div className='details'>
                                <p>Madhu Kumar <span>signed the</span></p>
                                <Link>Website Design Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                        <li className='activity-list-item'>
                            <span className='avatar'>JP</span>
                            <div className='details'>
                                <p>Joseph <span>commented on</span></p>
                                <Link>NDA Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                        <li className='activity-list-item'>
                            <span className='avatar'>MK</span>
                            <div className='details'>
                                <p>Madhu Kumar <span>signed the</span></p>
                                <Link>Website Design Agreement</Link>
                                <span className='timeine'>2 hours ago</span>
                            </div>
                        </li>
                    </ul>
                </Scrollbars>
            </div>
        </div>
    )
}


export default Dashboard;