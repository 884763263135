import React from 'react';
 
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles, createTheme } from "@material-ui/core/styles";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
// //   PaperProps: {
// //     style: {
// //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// //       width: 250,
// //     },
// //   },
// };

function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}


const names = [
    'Oliver Hansen',
    'Van Henry',
    'April Tucker',
    'Ralph Hubbard',
    'Omar Alexander',
    'Carlos Abbott',
    'Miriam Wagner',
    'Bradley Wilkerson',
    'Virginia Andrews',
    'Kelly Snyder',
  ];

function SimpleSelect({label,placeholder,options,labelId,id,onInputChange,inputValue,multiple=false,errors,register,fieldName,isrequired,errorText}) {
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);

    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#aaa"
        }
    }));

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };


    // const handleChange = (event) => {
    //     const {
    //     target: { value },
    //     } = event;
    //     setPersonName(
    //     // On autofill we get a stringified value.
    //     typeof value === 'string' ? value.split(',') : value,
    //     );
    // };
    return (
        <React.Fragment>
            {/* <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Sample Select</InputLabel>
                <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                placeholder='selct'
                value={personName}
                onChange={handleChange}
                input={<OutlinedInput label="Name" />} 
                >
                {names.map((name) => (
                    <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, personName, theme)}
                    >
                    {name}
                    </MenuItem>
                ))}
                </Select>
            </FormControl> */}
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">{label ? (label + ( {isrequired}  && "*")) : ''}</InputLabel>
                <Select
                name={fieldName}
                labelId={labelId}
                multiple={multiple}
                id={id}
                placeholder={placeholder?placeholder:""}
                {...register(fieldName, { required: { value: isrequired, message: errorText+" is required" } })}
                value={inputValue}
                onChange={onInputChange}
                renderValue={
                    ((inputValue ? inputValue.length : parseInt("0") )  !== 0 )? undefined : () => <Placeholder>Select {placeholder ? placeholder : ""}</Placeholder>
                 }
                input={<OutlinedInput label="Name" />}
                displayEmpty
                >
                {options.map((item) => (
                    <MenuItem
                    key={item._id}
                    value={item._id}
                    style={getStyles(item.name, personName, theme)}
                    >
                    {item.name}
                    </MenuItem>
                ))}
                </Select>
                {errors[fieldName] && <span className='form_error'>{errors[fieldName].message}</span>}
            </FormControl>
        </React.Fragment>
    )
}

export default SimpleSelect;