import React from 'react';
import { useState,useEffect } from 'react';
import { useForm } from 'react-hook-form';
import '../styles/scss/table.scss';
import '../styles/scss/dialog.scss';
import '../styles/scss/modal.scss';
import { DropzoneArea } from 'material-ui-dropzone';
import { EndPointService } from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
import { DateUtilService } from '../services/dateUtilService';
import { StorageService } from '../services/storageService';
import Simpleinput from './components/simpleInput';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from "@material-ui/core/styles";
import Close from '../images/x-mark.svg';
import Grid from '@mui/material/Unstable_Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';

import NoData from './components/noData'
import 'react-toastify/dist/ReactToastify.css';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Pagination from '@mui/material/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import { TextField } from '@mui/material';
import{isEmpty,showToast,isApiLoading} from "./Utils";
import SimpleSelect from './components/simpleselect'
const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#aaa"
    }
}));

const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};


function Categories() {
    const [modalOpen, modalActive] = React.useState(false);
    const [showNoData, setShowNoData] = React.useState(true)
    const [modalStatusChangeOpen, modalStatusChangeActive] = React.useState(false)  
    const [modalEditOpen, modalEditActive] = React.useState(false)
    const [companyId, setCompanyId] = React.useState("")
    const[name,setName] = React.useState("")
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const [statusChangeData, setStatusChangeData] = React.useState({
        categoryId: "",
         uuid: "",
         status:"",
         statusName:"",
         
     })
const [userData, setUserData] = useState(null);
const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, reset: reset1, clearErrors: clearErrors1, control:control1 } = useForm();
const { register: register2, reset: reset2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, clearErrors: clearErrors2, control: control2 } = useForm({
    mode: "all",
    reValidateMode: "all"
});
    const formFieldsList = [
        { name: "name",label: "Name", placeholder: " Name", required: true, helperText: "",md: 12,xs: 12 },     
            ]
     const updateFormFieldsList = [
    { name: "name", type: "", label: "Name", placeholder: "Name", required: true, helperText: "", md: 12, xs: 12 },
            ]
    const registerOptions = {
        Name: {
            required: {
                value: true,
                message: "Name is required."
            },
        },
        Company: {
            required: {
                value: true,
                message: "Company is required."
            },
        },
    
    }
    
    const [formDetails, setFormDetails] = React.useState({
        companyId:"",
        name:"",
        companiesList:"",    
          
    })
    const {  LoadingSpinner } = isApiLoading()
    const [companiesList, setCompaniesList] = React.useState([])
    const [isLoading, setIsLoading] = useState(false);
    const[categoryList,setCategoryList] =React.useState([])
    const [editName, setEditName] = React.useState("");
    const [paginationProps, setPaginationProps] = React.useState({
        count: 1,
    })
 
    const [filters, setFilters] = React.useState({
        page: 1,
        perpage: 6,
        filters: {
            title: "",
            status: [],
            name: [],
            createdOnRange: []
        },
        sorting: {
            path: "createdOn", // statusName,createdByName, name, email, phone, createdOn, updatedOn
            order: -1
        }
    })
    useEffect(() => {
        const storageService = new StorageService();
        const userinfoData = JSON.parse(storageService.get("userinfo"))
        setUserData(userinfoData)
    
    }, []);
     //to update category status
    const onCategoryStatusUpdate = (e) => {
        e.preventDefault()
        // console.log("request starting update category status.................")
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getCategoryUpdateStatus (),
            headers: token,
            data: {
                categoryId: statusChangeData.categoryId,
                uuid:statusChangeData.uuid,
                status: statusChangeData.status
            }
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        //toast.success(response.result.message)
                        showToast(response.message,"success")
                        onCancelStatusUpdate()
                    }
                    else {
                       // let { message, error } = response.result.error_message
                        //toast.error(message)
                        showToast(response.message,"error")
                    }

                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    //toast.error(error)
                    showToast(error,"errors")
                }
            );
    }
    const [categoryDetails, setCategoryDetails] = React.useState({
        categoryId: "",
        name: "",
       uuid:"",
       status:"",
        browserTS: "",
    })
    const getAccessToken = () => {
    const storageService = new StorageService();
    return (storageService.get() === "undefined") ? "" : JSON.parse(storageService.get("userinfo")).accessToken
}
   const onChangePage = (e, value) => {
        // console.log("page changed to ", value)
        setFilters((prev) => ({ ...prev, page: value }))
    }
   
     const renderStatus = (status) => {
        return status ? "Active" : "Inactive";
      };
    //to get companies list
    const getCompaniesList = () => {
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getCompaniesListUrl(),
            headers: token,
            data:filters
        })
            .subscribe(
                //onNext callback
                (response) => {
                    if (response.status_code === 200) {
                        let { list, totalCount, perpage } = response.result
                        let initialCompany = list[0]
                        setCompaniesList(list)
                      
                    }
                    else {
                        let { message, error } = response.result.error_message
                        setCompaniesList([])
                       // toast.error(message)
                        //setShowNoData(true)
                    }

                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    setCompaniesList([])
                    //toast.error(error)
                    showToast(error,"error")
                }
            );
    }

      const showModal = event => {
        modalActive(current => !current);
        clearErrors1()
    };

    const hideModal = () => modalActive(false); 
    const hideEditModal = () => modalEditActive(false);
    const hideStatusChangeModal = () => modalStatusChangeActive(false)
 
  useEffect(() => {
    getCompaniesList()
    getCategoryList()
}, [filters, modalOpen, modalEditOpen, modalStatusChangeOpen])

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === 1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
    
    //to create new categories
    function createCategory (data) {
        setIsLoading(true)
    const endpointService = new EndPointService();
    const dateUtilService = new DateUtilService();
    const fetchService = new FetchService();
    const token = getAccessToken()
    const { name,company } = data
    console.log("data  ",data)
    fetchService.post({
        url: endpointService.getCreateCategory(),
        headers: token,
        data: {
            name,
            companyId:!isEmpty(company)?company:userData.parentId,
            "timezone": dateUtilService.getTimeZone(),
            "browserTS": dateUtilService.getCurDateTime()
        }
    })
        .subscribe(
            //onNext callback
            (response) => {
                setIsLoading(false)
                if (response.status_code === 200) {
                    let { result } = response
                    console.log("category creation result ....  ", result)
                    //toast.success(result.message)
                    showToast(response.message,"success")
                    setCompanyId("")
                    setName("")
                    //clearErrors()
                    hideModal()
                    onClickCancel()
                }
                else {
                    let { message, error } = response.result.error_message
                    //toast.error(message)
                    showToast(response.message,"error")
                }

            },
            
            //onError callback
            (error) => {
                console.error("There has been an error", error);

            }
        );
}
  
const onClickCancel = () => {

    reset1({
        companyId:"",
        name:"",
    })
    setFormDetails({
        companyId:"",
        name:"",
    })
    setCompanyId("")
    clearErrors1()
    hideModal()
   
}

//to get category details
     const getCategoryDetails = () => {
        const dateUtilService = new DateUtilService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        const token = getAccessToken()
        fetchService.post({
            url: endpointService.getCategoryDetails(),
            data: {
                categoryId:"",
            },
        })
            .subscribe(
                //onNext callback
                (response) => {
                    let { result } = response
                    if (response.status_code === 200) {

                        let { result } = response
                        toast.success(result.message)
                    
                              toast.success(result.message)
               const categoryDetails = {
                companyId: result._id ? result._id : "",
                name: result.name ? result.name : "",
               }
                setCategoryDetails(categoryDetails)
                modalEditActive(true)
                reset1(categoryDetails)
                        }
                    else {
                        let { error_message } = response.result
              
                        toast.error(error_message)
                    }
                },
                //onError callback
                (error) => {
                    console.error("There has been an error", error);
                    alert(error)

                }
            );
    }
     const onChangeEditFormDetails = (e) => {
        //const { name, value } = e.target
        // console.log("input is ", name, " : ", value)

        setCategoryDetails((previous) => ({
            ...previous,
            [e.target.name]: e.target.value
        }))
        if (e.target.value) {
            clearErrors2(e.target.name);
        }
    }
  
    const onChangeFormDetails = (e) => {
                const { name, value } = e.target
                setFormDetails((previous) => ({
              ...previous,
                    [name]: value
                }))
                clearErrors1(name)
            }
    const onSelectCompany=(e)=>{
        const { name, value } = e.target
        setCompanyId(value)
        clearErrors1(name)
    }
//to update category details
const onUpdateCategoryDetails = () => {
   // e.preventDefault()
    // console.log("you clicked on edit submit ....")
  
    const dateUtilService = new DateUtilService();
    const endpointService = new EndPointService();
    const fetchService = new FetchService();
    const token = getAccessToken()

    fetchService.post({
        url: endpointService. getCategoryDetailsUpdate (),
        data: {
            ...categoryDetails,
            timezone: dateUtilService.getTimeZone(),
            browserTS: dateUtilService.getCurDateTime()
        },
        headers: token
    })
        .subscribe(
            //onNext callback

            (response) => {
                // console.log("response   update company ", response)

                if (response.status_code === 200) {
                    let { result } = response
                    let { message } = result
                    //toast.success(message)
                    showToast(response.message,"success")
                    onCancelDetailsUpdate()
                    setName("")
                    hideEditModal()
                    // console.log("result   update company ", result)
                }
                else {
                    let { error_message } = response.result
                    let { message, error } = error_message
                    //toast.error(message)
                    showToast(response.message,"error")
                    
                    // console.log("error result   update company ", error_message)
                }
            },
            //onError callback
            (error) => {
                console.error("There has been an error", error);
                alert(error)

            }
        );
}
 // to cancel update category details 
 const onCancelDetailsUpdate = () => {
   
    setCategoryDetails({
        categoryId: "",
        name: "",
        uuid:"",
        status:"",
        browserTS: "",
    })
    setName("")
    clearErrors2()
    hideEditModal()
}


//to edit category details

const onEditCategory = (categoryId) => {
  
    const selectedCategory = categoryList.find((category) => category._id === categoryId);
    if (selectedCategory) {
        setSelectedCategoryId(categoryId);
       
        setCategoryDetails({
            categoryId: selectedCategory._id ? selectedCategory._id : "",
            name: selectedCategory.name ? selectedCategory.name : "",
            
        });
        setEditName("");
        modalEditActive(true); 
    }
};

const onClickChangeStatus = (categoryId, status, statusName) => {
    modalStatusChangeActive(true)
    setStatusChangeData({ categoryId, status, statusName })
}


// to search value changes
  const onChangeSearchInput = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      filters: { ...prev.filters, [name]: value },
    }));
  };
//to cancel status update
const onCancelStatusUpdate = () => {

    modalStatusChangeActive(false)
    setStatusChangeData({})
}
    //to get categories list
    const getCategoryList = () => {
    const endpointService = new EndPointService();
    const fetchService = new FetchService();
    const token = getAccessToken()
   
    fetchService.post({
        url: endpointService.getCategoryList(),
        headers: token,
        data:filters
                     })
        .subscribe(
            //onNext callback
            (response) => {
                setCategoryList([])
                // if (response.status_code === 200) {
                //     let { list, totalCount, perpage } = response.result
                //     let count = Math.ceil(totalCount / perpage)
                //     setPaginationProps((prev) => ({ ...prev, count }))
                //     if (totalCount) {
                //         setShowNoData(false)
                //         setCategoryList(list)
                //     }
                //     else {
                //         setShowNoData(true)
                //     }
                // }
                // else {
                //     let { message, error } = response.result.error_message
                //     setShowNoData(true)
                //     toast.error(message)
                // }
                if(response.status=="Success"){
                    if(!isEmpty(response.result.list)&&response.result.list.length>0){
                        let { list, totalCount, perpage } = response.result
                        let count = Math.ceil(totalCount / perpage)
                        setPaginationProps((prev) => ({ ...prev, count }))
                        if (totalCount) {
                            setShowNoData(false)
                            setCategoryList(list)
                        } 
                    }else{
                        setShowNoData(false)
                    }   
                }else{
                    setShowNoData(false)
                    showToast(response.message,"error")
                }
                           
            },
            //onError callback
            (error) => {
                console.error("There has been an error", error);
                setShowNoData(false)
                showToast(error,"error")
            }
        );
}
    if (getAccessToken() === "") {
        return (
            <Redirect to="/login" />
        )
    }
    const renderCategoriesListView = () => {
        return (
            <>
            <div className='body-content'>
                <div className='eq-table'>
                <TableContainer component={Paper}>
                    
                    <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Created By Name</TableCell>
                        <TableCell>Created On</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                        <TableBody>
                        {categoryList.map((row) => {
                           // let changeStatusText = row.status ? "Inactivate" : "Activate";
                           // let changeToStatus = row.status ? false : true;
                            return (
                                <TableRow key={row._id}>
                                    <TableCell>
                                        <span>{row.name}</span>
                                    </TableCell>
                                    <TableCell>
                                        <span> {row.createdByName}</span>
                                    </TableCell>
                                    <TableCell>
                                        <span>{new DateUtilService().getFormattedDate(row.createdOn)}</span>
                                    </TableCell>
                                    <TableCell>
                                        <span>{renderStatus(row.status)}</span>
                                    </TableCell>
                                    <TableCell>
                                        <div className="d-inline-flex align-center">
                                            <div className="actions-td">
                                                <Tooltip
                                                    enterTouchDelay={0}
                                                    placement="bottom-end"
                                                    id="actions-menu"
                                                    effect="solid"
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <div className="actions-menu">
                                                                <ul>
                                                                    <li>
                                                                        <Link>View</Link>
                                                                    </li>
                                                                    <li onClick={() => onClickChangeStatus(row._id, row.status ? false : true, row.status ? "Inactivate" : "Activate")} >
                                                                                <Link>{row.status ? "Inactivate" : "Activate"}</Link>
                                                                            </li>
                                                                    <li onClick={() => onEditCategory(row._id)}>
                                                                        <Link>Edit</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <span className="actions-icon"></span>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer> 
                </div>
                </div>
                <Pagination
                    count={paginationProps.count}
                    page={filters.page}
                    onChange={onChangePage}
                    defaultPage={6} 
                    siblingCount={0} 
                    boundaryCount={2}
                    size='large'
                    color="secondary"
                    variant="outlined"
                    shape="rounded" />
            </>  
                 
        )
    }
    return (
        <React.Fragment>
          {/* <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />*/}
            <div className='sub-header'>
                <div className='page-title'>
                    <h3>Categories</h3>
                </div>  
                <div className='page-filters'>
                    <ul>
                        <li>
                            <div className="search_area">
                              <input type="text" className="form-control" placeholder="Search for email or file name" name="title" onChange={onChangeSearchInput}/>                </div>
                        </li>
                        <li>
                            <button className='primary_btn' onClick={showModal}>Add New</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body-content'>
                <div className='eq-table'>
                     {/* {showNoData && <NoData />}  */}
                     {<NoData 
                        isLoading={showNoData}
                        arrayList={categoryList}
                     />} 
                    {/*{!showNoData && renderCategoriesListView()}*/}
                     {!showNoData &&!isEmpty(categoryList)&&categoryList.length>0&& renderCategoriesListView()}
                     {/* {!isEmpty(categoryList)&&categoryList.length==0?<h2>No Data</h2>:renderCategoriesListView()} */}
                </div>
            </div>
            {/* create new category */}
            <div className={modalOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>New Category</h2>
                            <a className="close_btn" onClick={onClickCancel}><img src={Close} alt='close' /></a>
                        </div>
                        <form>
                            <div className='modal-body'>
                                <Grid container xs={12} spacing={0}>
                                    {formFieldsList.map((field) => {
                                     
                                        return (
                                            <Grid md={field.md} xs={field.xs}>  
                                         <div className='form-group'>
                                                     <Simpleinput
                                                         name={field.name}
                                                         fieldName={field.name}
                                                         label={field.label}
                                                         variant="outlined"
                                                         hideLabel={true}
                                                         placeHolder={field.placeholder}
                                                         errorText={field.placeholder}
                                                         register={register1}
                                                         inputValue={formDetails[field.name]}
                                                         errors={errors1}
                                                         onInputChange={onChangeFormDetails}
                                                         type={field.type}
                                                         control={control1}
                                                         isRequired={field.required}
                                                    />
                                                </div>
                                                <div className='form-group'>         
                                                {!isEmpty(userData)&&isEmpty(userData.parentId)&&<SimpleSelect
                                                   labelId="demo-multiple-name-label"
                                                   id="demo-multiple-name"
                                                   label="Company"
                                                   placeholder='Companies List'
                                                   options={companiesList}
                                                   onInputChange={onSelectCompany}
                                                   inputValue={companyId}
                                                   errors={errors1}
                                                   register={register1}
                                                   fieldName="company"
                                                   isrequired={true}
                                                   errorText="Company"
                                                   />}


                                {/* <InputLabel id="demo-multiple-name-label">Company List</InputLabel> */}
                                {/* <Select style={{ "minWidth": "200px" }}
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    placeholder='Companies List'
                                    name="companyId"
                                    {...register("company" ,registerOptions["company"])}
                                    value={companyId}
                                   // onChange={(e) =>setCompanyId(e.target.value)}
                                   onChange={onSelectCompany}
                                    input={<OutlinedInput label="Name" />}
                                >
                                    <MenuItem
                                        key=""
                                        value=""
                                    >
                                    </MenuItem>
                                    {companiesList.map((company) => (
                                        <MenuItem
                                            key={company._id}
                                            value={company._id}

                                        >
                                            {company.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors?.["company"] && <p className='form_error'>{errors["company"].message}</p>} */}
                                </div>
                         
                                            </Grid>
                                        )
                                    })}
                                </Grid>

                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={onClickCancel} >Cancel</button>
                                <button className="primary_btn active" type='submit' onClick={handleSubmit1(createCategory)} >Add</button>
                            </div>
                                 
                        </form>
                    </div>
                </div>
                {isLoading&&<LoadingSpinner/>}
            </div>
             {/*update category details */}
            <div className={modalEditOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>Update Category Details</h2>
                            <a className="close_btn" onClick={hideEditModal}><img src={Close} alt='close' /></a>
                        </div>
                        <form>
                            <div className='modal-body'>
                                <Grid container xs={12} spacing={1.3}>
                                    {updateFormFieldsList.map((field) => {
                                       
                                        return (
                                            <Grid md={field.md} xs={field.xs}>
                                                <div className='form-group'>
                                                <Simpleinput
                                                         name={field.name}
                                                         fieldName={field.name}
                                                         label={field.label}
                                                         variant="outlined"
                                                         hideLabel={true}
                                                         placeHolder={field.placeholder}
                                                         errorText={field.placeholder}
                                                         register={register2}
                                                         inputValue={categoryDetails[field.name]}
                                                         errors={errors2}
                                                         onInputChange={onChangeEditFormDetails}
                                                         type={field.type}
                                                         control={control2}
                                                         isRequired={field.required}
                                                         
                                                    />
                                                   
                                                </div>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={onCancelDetailsUpdate} >Cancel</button>
                                <button className="primary_btn active" type='submit' onClick={handleSubmit2(onUpdateCategoryDetails)}>Update</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
             {/* update category status */}
            <div className={modalStatusChangeOpen ? "custom_modal open" : "custom_modal"} >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title'>category Status Change</h2>
                            <a className="close_btn" onClick={onCancelStatusUpdate}><img src={Close} alt='close' /></a>
                        </div>
                        <form onSubmit={onCategoryStatusUpdate}>
                            <div className='modal-body'>
                                <p>Click on Confirm to Inactivate</p>
                            </div>
                            <div className='modal-footer'>
                                <button className="primary_btn more" type='button' onClick={onCancelStatusUpdate} >Cancel</button>
                                <button className="primary_btn active" type='submit'  >Confirm</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
           
        </React.Fragment>
    )
}
export default Categories;

