import React, { useEffect, useState } from 'react';
//import { ToastContainer, toast } from 'react-toastify'
import '../styles/scss/login.scss';
import '../styles/scss/components/simpleInput.scss';
import Envelope from '../images/envelope.svg';
import Padlock from '../images/padlock.svg';
import Company from '../images/company.svg';
import User from '../images/user.svg';
import Phone from '../images/phone-call.png';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Simpleinput from './components/simpleInput';
import { useForm } from "react-hook-form";
import { EndPointService } from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';
import { DateUtilService } from '../services/dateUtilService';
import { showToast, isApiLoading } from './Utils'
import { useHistory } from 'react-router-dom';
import Loader from '../images/loader.gif';
function SignUp() {
    useEffect(() => {
        document.body.classList.add('login-layout')
        return () => {
            document.body.classList.remove('login-layout')
        }
    }, [])
    const [value, setValue] = React.useState('individual');
    const [company, setCompany] = React.useState({
        companyName: '', firstName: '', lastName: '', phoneNumber: '', email: '', password: '',
        phoneCountryCode: {
            countryCode: 'US',
            countryCallingCode: '1'
        }
    });


    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const { register, clearErrors, handleSubmit, formState: { errors }, control, trigger } = useForm();
    const { LoadingSpinner } = isApiLoading()
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleInputChange = (e, data, formattedValue, isPhoneNumber) => {
        const { name, value } = e.target
        if (isPhoneNumber) {
            if (name && name == 'phoneNumber') {
                let phoneNumber = value.slice(data.dialCode.length)
                setCompany((previous) => ({
                    ...previous,
                    ['phoneNumber']: phoneNumber
                }))
            } else {
                const phoneCode = {
                    countryCode: data.countryCode,
                    countryCallingCode: data.dialCode
                }
                //let phoneNumber = data.dialCode + " " + company.phoneNumber.slice(data.dialCode.length)
                setCompany((previous) => ({
                    ...previous,
                    ['phoneCountryCode']: phoneCode,
                   //['phoneNumber']: '',
                }))
                // setTimeout(() => {
                //     setCompany((previous) => ({
                //         ...previous,
                //         ['phoneNumber']: phoneNumber,
                //     }))
                //     //trigger();
                // }, 1)
            }
        } else {
            setCompany((previous) => ({
                ...previous,
                [name]: value
            }))
        }

        if (!isPhoneNumber && value) {
            clearErrors(name);
        }

        //  reset(); 
    };

    useEffect(() => {
        console.log("input data company", company)
    }, [company])



    function onSubmit(data) {
        setIsLoading(true)
        const dateUtilService = new DateUtilService();
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        fetchService.post({
            url: endpointService.companyRegister(),
            data: {
                name: company.companyName,
                email: company.email,
                phone: data.phoneNumber,
                phoneCountryCode: company.phoneCountryCode,
                type: 'Corporate',
                pwd: company.password,
                timezone: dateUtilService.getTimeZone(),
                browserTS: dateUtilService.getCurDateTime()
            },
        }).subscribe(
            (response) => {
                setIsLoading(false)
                if (response.status === 'Success') {
                    showToast(response.message, "success")
                    history.push('/login')
                } else {
                    showToast(response.message, "error")
                }

            },
            (error) => {
                console.error("There has been an error", error);
            }
        );

    };
    const onErrors = (errors) => {
        console.log("errors are ", errors)
    }
    return (
        <div className="login_page_wrapper">
            <div className="login_area">
                <h4>Unlock unlimited access for 7 days</h4>
                <div className="login-form">
                    <form >
                        {false && <FormControl component="fieldset" >
                            <RadioGroup row aria-label="position" name="position" defaultValue="individual" value={value} onChange={handleChange}>
                                <FormControlLabel value="individual" control={<Radio color="primary" />} label="Individual" className='radio_btn' />
                                <FormControlLabel value="corporate" control={<Radio color="primary" />} label="Corporate" className='radio_btn' />
                            </RadioGroup>
                        </FormControl>}
                        <div className="form-group">
                            <img src={Company} alt="Company" />
                            <Simpleinput
                                id={'companyName'}
                                fieldName={'companyName'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"Company Name"}
                                errorText={"Company Name"}
                                register={register}
                                inputValue={company.companyName}
                                errors={errors}
                                isRequired={true}
                                onInputChange={handleInputChange} />
                        </div>
                        {/* <div className="form-group">
                            <img src={User} alt="User" />
                            <TextField placeholder='First Name' hiddenLabel variant="outlined" name="firstName" value={firstName}
                                onChange={handleChanging} />
                        </div>
                        <div className="form-group">
                            <img src={User} alt="User" />
                            <TextField placeholder='Last Name' hiddenLabel variant="outlined" name="lastName" value={lastName}
                                onChange={handleChanging} />
                        </div> */}
                        <div className="form-group">
                            {/* <img src={Phone} alt="Phone" /> */}
                            <Simpleinput
                                id={'phoneNumber'}
                                fieldName={'phoneNumber'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"Phone Number"}
                                errorText={"Phone Number"}
                                register={register}
                                inputValue={company.phoneNumber}
                                errors={errors}
                                isRequired={true}
                                type={'phone'}
                                control={control}
                                phoneCountryCode={company.phoneCountryCode}
                                onInputChange={handleInputChange} />
                            {/* <TextField placeholder='Phone Number' hiddenLabel variant="outlined" name="phoneNumber" value={phoneNumber}
                                onChange={handleChanging} /> */}
                        </div>
                        <div className="form-group">
                            <img src={Envelope} alt="Envelope" />
                            <Simpleinput
                                id={'email'}
                                fieldName={'email'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"Email Address"}
                                errorText={"Email Address"}
                                register={register}
                                inputValue={company.email}
                                errors={errors}
                                isRequired={true}
                                type={'email'}
                                onInputChange={handleInputChange} />
                            {/* <TextField placeholder='Email Address' hiddenLabel variant="outlined" name="email" value={email}
                                onChange={handleChanging} /> */}
                        </div>
                        <div className="form-group">
                            <img src={Padlock} alt="Padlock" />
                            <Simpleinput
                                id={'password'}
                                fieldName={'password'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"Password"}
                                errorText={"Password"}
                                register={register}
                                inputValue={company.password}
                                errors={errors}
                                isRequired={true}
                                type={'password'}
                                onInputChange={handleInputChange}
                            />

                            {/* <TextField placeholder="Password" hiddenLabel variant="outlined" name="password" value={password}
                                onChange={handleChanging} /> */}
                        </div>
                        <button type="button" className="primary_btn login" onClick={handleSubmit(onSubmit, onErrors)}>
                            Sign up
                        </button>
                        {/* <ToastContainer
                            position="bottom-right"
                            autoClose={3000}
                            hideProgressBar={true}
                            closeOnClick
                            draggable
                        /> */}
                        <div className="sign_up">
                            <p>Already have an account? <Link to="/login"><span>Sign In</span></Link></p>
                        </div>
                    </form>
                </div>
            </div>
            {isLoading && <LoadingSpinner />}
        </div>
    )
}


export default SignUp;