import React from 'react';
import { Link } from 'react-router-dom';

import { DropzoneArea } from 'material-ui-dropzone';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from "@material-ui/core/styles";


const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: "#aaa"
    }
  }));
  
  const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
  };

function SendForSignature() {
    const [answer, setAnswer] = React.useState("");
    const [answers, setAnswers] = React.useState("");
  
    return (
        <React.Fragment>
            <div className='sub-header v2'>
                <div className='page-title'>
                    <h3>Send for Signature</h3>
                </div>
                <div className='page-filters'>
                    <ul>
                        <li>
                            <button className='btn_light'>Cancel</button>
                        </li>
                        <li>
                            <button className='secondary_btn'>Save</button>
                        </li>
                        <li>
                            <button className='primary_btn'>Continue</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='body-content'>
                <div className='padt20 es-accordian'>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Add Documents</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid md={6} xs={12}>
                                    <DropzoneArea
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        dropzoneClass={'customdropzone'}
                                        dropzoneText="Drag and drop here or Browse"
                                        // previewChipProps={{classes: { root: classes.previewChip } }}
                                        previewText="Selected files"
                                    />
                                </Grid>
                                <Grid md={6} xs={12} className="saved-template">
                                    <DropzoneArea
                                        showPreviews={true}
                                        showPreviewsInDropzone={false}
                                        useChipsForPreview
                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                        dropzoneClass={'customdropzone'}
                                        dropzoneText="Choose Saved Template"
                                        // previewChipProps={{classes: { root: classes.previewChip } }}
                                        previewText="Selected files"
                                    />
                                </Grid>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                    <Accordion expand>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <div className='header-actions'>
                                <Typography>Add Recipients</Typography>
                                <div className='form-check'>
                                    <FormControlLabel control={<Checkbox />} label="I’m the only Signer" />
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='recipient-actions'>
                                <div className='form-check'>
                                    <FormControlLabel control={<Checkbox />} label="Sign in Sequence" />
                                </div>
                                <div className='form-check'>
                                    <FormControlLabel control={<Checkbox />} label="Notify in Order only" />
                                </div>
                            </div>
                            <div className='add-signer-sec'>
                                <ul>
                                    <li>
                                        <span class="order"></span>
                                        <Grid container xs={12} spacing={1.3}>
                                            <Grid md={6} xs={6}>
                                                <div className='form-group'>
                                                    <TextField hiddenLabel placeholder="Name" />
                                                </div>
                                            </Grid>
                                            <Grid md={6} xs={6}>
                                                <div className='form-group'>
                                                    <TextField hiddenLabel placeholder="Email" />
                                                </div>
                                            </Grid>
                                        </Grid>
                                        <div className='form-group'>
                                            <FormControl>
                                                <Select
                                                    value={answer}
                                                    displayEmpty
                                                    onChange={event => setAnswer(event.target.value)}
                                                    renderValue={
                                                        answer !== "" ? undefined : () => <Placeholder>Select</Placeholder>
                                                    }
                                                >
                                                    <MenuItem value={10}>Need to Sign</MenuItem>
                                                    <MenuItem value={20}>Receives a copy</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <span class="delete-row"></span>
                                    </li>
                                </ul>
                                <Link className='add-signer-link'>Add Signer</Link>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expand>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <div className='header-actions'>
                                <Typography>Add Message</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='form-group'>
                                <TextField label="Email Subject*" variant="outlined" helperText="Characters remaining: 100" />
                            </div>
                            <div className='form-group'>
                                <TextField label="Email Message" variant="outlined" helperText="Characters remaining: 10000" />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expand>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <div className='header-actions'>
                                <Typography>More Settings</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid lg={2} md={6} sm={12}>
                                    <div className='form-group'>
                                        <TextField label="Days to complete" variant="outlined" />
                                    </div>
                                </Grid>
                                <Grid lg={2} md={6} sm={12}>
                                    <div className='form-group'>
                                        <FormControl>
                                            <InputLabel id="demo-multiple-name-label">Agreement valid until</InputLabel>
                                            <Select
                                            value={answers}
                                            displayEmpty
                                            onChange={event => setAnswers(event.target.value)}
                                            renderValue={
                                                answers !== "" ? undefined : () => <Placeholder>Select</Placeholder>
                                            }
                                            >
                                                <MenuItem value={10}>Needs to sign</MenuItem>
                                                <MenuItem value={11}>Forever</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                                <Grid md={3} xs={12}>
                                    <div className='form-group input_group'>
                                        <TextField label="Send a reminder every" variant="outlined"/>
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </React.Fragment>
    )
}


export default SendForSignature;