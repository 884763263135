import React, { useState,useEffect } from 'react';
import '../styles/scss/Header.scss';
import '../styles/scss/templates.scss';
import '../styles/scss/settings.scss';
import Notification from '../images/notification.png';
import Logo from '../images/logo.png';
import Close from '../images/x-mark.svg';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Scrollbars } from 'react-custom-scrollbars';
import Send from '../images/send.svg';
import Sign from '../images/sign-white.svg';
import SideNav, { MenuIcon } from 'react-simple-sidenav';
import Tooltip from '@mui/material/Tooltip';
import { useHistory, BrowserRouter as Router, Route, Link,NavLink ,useLocation } from 'react-router-dom';
import PasswordChangePopup from "./PasswordChangePopup"
import { StorageService } from '../services/storageService';
import { isEmpty } from './Utils';

function Header() {
    const [showNav, setShowNav] = useState();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const history = useHistory();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };
    const location = useLocation();
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const storageService = new StorageService();
        const userinfoData = JSON.parse(storageService.get("userinfo"))
        setUserData(userinfoData)
    
    }, []);
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);


    function logout(){
        localStorage.clear()
        history.replace("/login")

    }
    const openPopup = () => {
        setPopupOpen(true);
      };
    
      const closePopup = () => {
        setPopupOpen(false);
      };

    return (
        <header className='header'>
            <div id="logo">
                <Link to="/"><img src={Logo} alt="eStrokes Logo" /></Link>
                <MenuIcon onClick={() => setShowNav(true)} />
            </div>
            <div>
                <SideNav
                    openFromLeft={true}
                    showNav={showNav}
                    onHideNav={() => setShowNav(false)}
                    title="Hello World"
                    titleStyle={{ backgroundColor: '#4CAF50' }}
                    itemStyle={{ backgroundColor: '#fff' }}
                    itemHoverStyle={{ backgroundColor: '#CDDC39' }}
                >
                    <div className='mobile-nav'>
                        <nav>
                            <div className='sidenav-dropdown'>
                                <Button
                                    class="dropdown"
                                    ref={anchorRef}
                                    id="composition-button"
                                    aria-controls={open ? 'composition-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggle} >
                                    New Document
                                    <ArrowDropDownIcon />
                                </Button>
                                <button className="close_btn" onClick={() => setShowNav(false)}><img src={Close} alt='close' /></button>
                                <Popper
                                    class="dropdown-menu"
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    placement="bottom-start"
                                    transition
                                    disablePortal
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleClose}>
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id="composition-menu"
                                                        aria-labelledby="composition-button"
                                                        onKeyDown={handleListKeyDown}
                                                    >
                                                        <MenuItem onClick={handleClose}><img src={Send} alt="send" /> Send for Signature</MenuItem>
                                                        <MenuItem onClick={handleClose}><img src={Sign} alt="sign" /> Sign yourself</MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                            <Scrollbars style={{ height: "calc(100vh - 120px)" }}>
                                <ul>
                                    {/*  <li>
                     <Dropdown as={ButtonGroup}>
                        <Button variant="success" class="dropdown_btn">New Document</Button>
                        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />
                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1"><img src={Envelope} alt=""/>  Send for Signature</Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><img src={Envelope} alt=""/> Sign yourselfn</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown> 
                    
                    </li>*/}
                                    <li className='dashboard'>
                                        <NavLink exact to="/" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Dashboard</label>
                                        </NavLink>
                                    </li>
                                    <li className='documents'>
                                        <NavLink to="/documents" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>My Documents</label>
                                        </NavLink></li>
                                    <li className='documents'>
                                        <NavLink to="/companies" activeClassName="active">
                                            <span></span>
                                            <label>Companies</label>
                                        </NavLink></li>
                                    <li className='pending'>
                                        <NavLink to="/pending-with-me" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Pending with Me</label>
                                        </NavLink></li>
                                    <li className='others'>
                                        <NavLink to="/pending-with-others" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Pending with others</label>
                                        </NavLink>
                                    </li>
                                    <li className='completed'>
                                        <NavLink to="/completed" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Completed</label>
                                        </NavLink>
                                    </li>
                                    <li className='drafts'>
                                        <NavLink to="/drafts" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Drafts</label>
                                        </NavLink>
                                    </li>
                                    <li className='templates'>
                                        <NavLink to="/Templates" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Templates</label>
                                        </NavLink>
                                    </li>
                                    <li className='payments'>
                                        <NavLink to="/planAndPayments" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Plan and Payments</label>
                                        </NavLink>
                                    </li>
                                    <li className='reports'>
                                        <NavLink to="/reports" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Reports</label>
                                        </NavLink>
                                    </li>
                                    <li className='settings'>
                                        <NavLink to="/settings" activeClassName="active" onClick={() => setShowNav(false)}>
                                            <span></span>
                                            <label>Settings</label>
                                        </NavLink>
                                    </li>
                                </ul>
                            </Scrollbars>
                            <div className='navfooter'>
                                <div class="upgrade_btn">Enterprise Trial <span> UPGRADE</span></div>
                            </div>
                        </nav>
                    </div>
                </SideNav>
            </div>
            <div className="header_right">
                <ul>
                    <li>
                        <img src={Notification} alt="Notification" />
                    </li>
                    <li>
                        <Tooltip enterTouchDelay={0} placement="bottom-end" id="actions-menu" effect="solid"
                            arrow
                            title={
                                <React.Fragment>
                                    <div className='actions-menu'>
                                        <ul>
                                            {location.pathname!=="/settings"&&<li>
                                                <Link to="/settings">Profile</Link>
                                            </li>}
                                           
                                            <li>
                                                <Link onClick={openPopup}>Change Password</Link>
                                            </li>
                                            <li onClick={() => logout()} >
                                                <Link>Logout</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </React.Fragment>
                            }
                        >
                            <span className='actions-icon'>{!isEmpty(userData)&&!isEmpty(userData.name)?userData.name.slice(0, 2).toUpperCase():""}</span>
                        </Tooltip>


                    </li>
                </ul>
            </div>
            <PasswordChangePopup isOpen={isPopupOpen} onClose={closePopup} />
        </header>


    )
}


export default Header;