import React, { useState, useEffect, useRef } from 'react';
import '../styles/scss/settings.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DropzoneArea } from 'material-ui-dropzone';
import { AttachFile } from '@material-ui/icons';
import QuestionMark from '../images/question-mark.svg';
import Select from '@mui/material/Select';
import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimezoneSelect from 'react-timezone-select';
import { makeStyles } from "@material-ui/core/styles";
import { EndPointService } from '../services/endPointServices';
import { render } from '@testing-library/react';
import PhoneNumberInput from './components/PhoneNumberInput.js';
import Moment from 'moment';
import { isEmpty ,isApiLoading} from "./Utils";
//import { ToastContainer, toast } from 'react-toastify'
import { StorageService } from '../services/storageService';
import '../styles/scss/components/simpleInput.scss';
import Simpleinput from './components/simpleInput';
import { useForm } from "react-hook-form";
import Envelope from '../images/envelope.svg';
import UploadFileComponent from './UploadFileComponent.js';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import { FetchService } from '../services/fetchServices';
import { showToast } from './Utils'
const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
        color: "#aaa"
    }
}));
const Placeholder = ({ children }) => {
    const classes = usePlaceholderStyles();
    return <div className={classes.placeholder}>{children}</div>;
};
function Settings() {
    const [answer, setAnswer] = useState("");
    const [answers, setAnswers] = useState("");
    const [value, onChange] = useState(Moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"));
    const [selectedTimezone, setSelectedTimezone] = useState({})
    const [startDate, setStartDate] = useState(new Date());
    const [startDates, setStartDates] = useState(new Date());
    const [signature, setSignature] = useState([]);
    const [stamp, setStamp] = useState([]);
    const [userData, setUserData] = useState(null);
    const [companyUpdate, setCompanyUpdate] = useState({
        name: "", website: "", phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' }, phone: "",
        authorizedSignatory: {
            fName: "",
            lName: "",
            title: "",
            email: "",
            phone: "",
            phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' },
            homePhone: "",
            homePhoneCountryCode: { countryCode: 'US', countryCallingCode: '1' }
        }

    });
    const { LoadingSpinner } = isApiLoading()
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef();
    const { register, clearErrors, handleSubmit, formState: { errors }, control, setValue, reset, trigger,setError } = useForm(
        {
            defaultValues: companyUpdate,
        }
    );
    let isFirstTimeUpdate = true

    useEffect(() => {
        const storageService = new StorageService();
        const userinfoData = JSON.parse(storageService.get("userinfo"))
        setUserData(userinfoData)
        getUserDetails(userinfoData)
        //console.log("userinfoData",userinfoData)
        setCompanyUpdate((prevData) => ({
            ...prevData,
            name: userinfoData.name,
           // phone: userinfoData.phone,
            authorizedSignatory: {
                fName: "",
                lName: "",
                title: "",
                email: userinfoData.email,
                //phone: userinfoData.phone,
                phoneCountryCode: { countryCode: 'US', countryCallingCode: '1' },
               // homePhone: userinfoData.phone,
                homePhoneCountryCode: { countryCode: 'US', countryCallingCode: '1' }
            }
        }))
    }, []);


    useEffect(() => {
        if (isFirstTimeUpdate) {
            reset(companyUpdate)
            isFirstTimeUpdate = false
        }
        setValue('browserTS', value);
    }, [companyUpdate]);

    const getUserDetails=(userInfo)=>{
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        fetchService.post({
            headers: userInfo.accessToken,
            url: endpointService.getUserDetails(),
            data:{
                userId:userInfo._id,
            }
        }).subscribe(
            (response) => {
                
            },
            (error) => {
                console.error("Error", error);
            }
        );
}
    function handleSelectedFile(selectedFile, type) {
        if (type === 1) {
            setSignature(selectedFile)
        } else {
            setStamp(selectedFile)
        }

    }
    function handleDeletedFile (removedFile,type) {
        if(type=="signature"){
          //  const updatedFiles = signature.filter((file, index) => file !== removedFile);
            //setSignature(updatedFiles);
            setSignature([]);
        }else{
           // const updatedFiles = stamp.filter((file, index) => file !== removedFile);
           // setStamp(updatedFiles);
           setStamp([]);
        }
      
    
    };
    const handleInputChange = (e, data, formattedValue, isPhoneNumber,countryName) => {
       // console.log("type",type)
        console.log("input data", data)
        const { name, value } = e.target
        console.log("input name", name)
        console.log("input value", value)
        console.log("input formattedValue", formattedValue)
        console.log("input isPhoneNumber", isPhoneNumber)

        if (isPhoneNumber) {
            console.log("countryName",countryName)
            if(countryName=="authorizedPhone"){
                let nestedName = "";
                if (!isEmpty(name)) {
                    const splitName = name.split(".");
                    if (splitName.length > 1) {
                        nestedName = splitName[1];
                    }
                }
                let phoneCode = {
                    countryCode: data.countryCode,
                    countryCallingCode: data.dialCode
                }
                let phoneNumber=!isEmpty(data)&&!isEmpty(data.dialCode)? typeof value === 'string'&&value.slice(data.dialCode.length):""
                setCompanyUpdate((previous) => ({
                    ...previous,
                    authorizedSignatory: {
                        ...previous.authorizedSignatory,
                        ['phoneCountryCode']: phoneCode,
                        [nestedName]:""
                        
                    },
                }));
                setTimeout(() => {
                    setCompanyUpdate((previous) => ({
                        ...previous,
                        authorizedSignatory: {
                            ...previous.authorizedSignatory,
                            [nestedName]: phoneNumber,
                        },
                    }))
                }, 1)
                trigger();
                console.log("authorizedPhone", companyUpdate)
            }else if(countryName=="authorizedHomePhone"){
                let nestedName = "";
                if (!isEmpty(name)) {
                    const splitName = name.split(".");
                    if (splitName.length > 1) {
                        nestedName = splitName[1];
                    }
                }
                let phoneCode = {
                    countryCode: data.countryCode,
                    countryCallingCode: data.dialCode
                }
                let phoneNumber=!isEmpty(data)&&!isEmpty(data.dialCode)? typeof value === 'string'&&value.slice(data.dialCode.length):""
                setCompanyUpdate((previous) => ({
                    ...previous,
                    authorizedSignatory: {
                        ...previous.authorizedSignatory,
                        ['homePhoneCountryCode']: phoneCode,
                        [nestedName]: '',
                    },
                }));
                setTimeout(() => {
                    setCompanyUpdate((previous) => ({
                        ...previous,
                        authorizedSignatory: {
                            ...previous.authorizedSignatory,
                            [nestedName]: phoneNumber,
                        },
                    }))
                }, 1)
                trigger();
                console.log("AuthorizedHomePhone", companyUpdate)
            }else{
                let phoneCode = {
                    countryCode: data.countryCode,
                    countryCallingCode: data.dialCode
                }
                let phoneNumber=!isEmpty(data)&&!isEmpty(data.dialCode)? typeof value === 'string'&&value.slice(data.dialCode.length):""
                console.log("phoneNumber",phoneNumber)
                setCompanyUpdate((previous) => ({
                    ...previous,
                    ['phoneCountryCode']: phoneCode,
                    ['phone']: '',
                }))
                setTimeout(() => {
                    setCompanyUpdate((previous) => ({
                        ...previous,
                        ['phone']: phoneNumber,
                    }))
                   // trigger();
                }, 1)
                console.log("inputphone", companyUpdate)
            }

        }else{
            if (name.startsWith("authorizedSignatory.")) {
                const [, nestedName] = name.split(".");

                setCompanyUpdate((prevState) => ({
                    ...prevState,
                    authorizedSignatory: {
                        ...prevState.authorizedSignatory,
                        [nestedName]: value,
                    },
                }));
                if (errors)
                    trigger();
                console.log("input name", companyUpdate)
            } else {
                setCompanyUpdate((prevState) => ({
                    ...prevState,
                    [name]: value,
                }));
                if (errors)
                    trigger();
            }
        }
        console.log("input data companyUpdate", companyUpdate)

    };
    function onSubmit(data) {
        setIsLoading(true)
        const endpointService = new EndPointService();
        const fetchService = new FetchService();
        data.companyId = userData.companyId
        //data.browserTS=Moment(value).format("YYYY-MM-DDTHH:mm:ssZ")
        //data.timezone= selectedTimezone.value
        data.signature = { "name": signature[0].name, "path": signature[0].path, "mimetype": signature[0].mimetype, "extn": signature[0].extn }
        data.stamp = { "name": stamp[0].name, "path": stamp[0].path, "mimetype": stamp[0].mimetype, "extn": stamp[0].extn }
        //    data.authorizedSignatory={
        //           "fName": data.fName,
        //           "lName": data.lName,
        // 			"title": data.jobTitle,
        // 			"email": data.email,
        // 			"phone": data.authorizedPhoneNumber,
        // 			"phoneCountryCode":  { 
        // 				"countryCode":"IN",
        // 				"countryCallingCode":"91"
        // 			},
        // 			"homePhone": data.homePhone,
        // 			"homePhoneCountryCode": {
        // 				"countryCode": "IN",
        // 				"countryCallingCode": "+91"
        // 			}
        //    }
        //data.website= "https://www.rmtportal.com" headers: token,
        data.phoneCountryCode = companyUpdate.phoneCountryCode
        data.phone=companyUpdate.phone
        data.authorizedSignatory.phoneCountryCode = companyUpdate.authorizedSignatory.phoneCountryCode
        data.authorizedSignatory.phone=companyUpdate.authorizedSignatory.phone
        data.authorizedSignatory.homePhoneCountryCode = companyUpdate.authorizedSignatory.homePhoneCountryCode
        data.authorizedSignatory.homePhone=companyUpdate.authorizedSignatory.homePhone
        data.contactEmail = data.authorizedSignatory.email
        data.signDocUrl = "http://localhost:4200/#/auth/sign-document"
        fetchService.post({
            headers: userData.accessToken,
            url: endpointService.getUpdateCompany(),
            data: data
        }).subscribe(
            (response) => {
                setIsLoading(false)
                if (response.status === 'Success') {
                    showToast(response.message, "success")
                } else {
                    showToast(response.message, "error")
                }
            },
            (error) => {
                console.error("Error", error);
            }
        );
    };
    const onErrors = (errors) => {
        console.log("errors are ", errors)
    }
    return (
        <div>
            <div className='side-nav-tabs'>
                <Tabs>
                    <TabList>
                        <Tab>
                            <p>Profile </p>
                        </Tab>
                        <Tab>
                            <p>Account</p>
                        </Tab>
                        <Tab>
                            <p> Notifications</p>
                        </Tab>
                        <Tab>
                            <p>Email Branding</p>
                        </Tab>
                        <Tab>
                            <p>Team Members</p>
                        </Tab>
                        <Tab>
                            <p>Teams</p>
                        </Tab>
                        <Tab>
                            <p>Integrations</p>
                        </Tab>
                        <Tab>
                            <p>API</p>
                        </Tab>
                    </TabList>
                    <div class="tab-panel-content">
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    {!isEmpty(userData) && <div className="profile-header"><div className="user">{userData.name}</div>
                                        <p><span>{userData.email}</span></p></div>}
                                    <div className="Profile-body profile-tab">
                                        <form>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12} sm={12} md={8}>
                                                    <UploadFileComponent
                                                        label={"Signature and Initial"}
                                                        className="file-upload"
                                                        showPreviews={false}
                                                        acceptedFiles={['image/*']}
                                                        filesLimit={1}
                                                        dropzoneText={!isEmpty(signature)&&signature.length>0?"":"+ Add"}
                                                        Icon={AttachFile}
                                                        showPreviewsInDropzone={true}
                                                        showFileNamesInPreview={true}
                                                        useChipsForPreview={false}
                                                        errorText={"Signature"}
                                                        isValidationRequired={true}
                                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                        handleSelectedFile={(selectedFile) => handleSelectedFile(selectedFile, 1)}
                                                        handleDelete={(deletedFile) => handleDeletedFile(deletedFile,"signature")}
                                                        selectedFile={signature}
                                                        fieldName={'Signature'}
                                                        errors={errors}
                                                        id={'Signature'}
                                                        control={control}
                                                        register={register}
                                                        clearErrors={clearErrors}
                                                    // userData={userData}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={4}>
                                                     <UploadFileComponent
                                                        label={"Stamp"}
                                                        showPreviews={false}
                                                        acceptedFiles={['image/*']}
                                                        filesLimit={1}
                                                        dropzoneText={!isEmpty(stamp)&&stamp.length>0?"":"+ Add"}
                                                        Icon={AttachFile}
                                                        //showPreviewsInDropzone={true}
                                                        errorText={"Stamp"}
                                                        isValidationRequired={true}
                                                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                        handleSelectedFile={(selectedFile) => handleSelectedFile(selectedFile, 2)}
                                                        handleDelete={(deletedFile) => handleDeletedFile(deletedFile,"stamp")}
                                                        selectedFile={stamp}
                                                        fieldName={'Stamp'}
                                                        errors={errors}
                                                        id={'Stamp'}
                                                        control={control}
                                                        showPreviewsInDropzone={true}
                                                        showFileNamesInPreview={true}
                                                        useChipsForPreview={false}
                                                        register={register}
                                                        clearErrors={clearErrors}
                                                    //userData={userData}
                                                    //   dropzoneClass={'customsdropzone'}
                                                    //  previewChipProps={{classes: { root: classes.previewChip } }}

                                                    /> 
                                                </Grid>
                                                <div className='devider'></div>

                                                <Grid item xs={12} sm={12} md={12}>
                                                    <div className='form-group' >
                                                        <Simpleinput
                                                            id={'Company'}
                                                            fieldName={'name'}
                                                            label={'Company'}
                                                            hideLabel={true}
                                                            placeHolder={"Company"}
                                                            errorText={"Company Name"}
                                                            register={register}
                                                            inputValue={companyUpdate.name}
                                                            errors={errors}
                                                            isRequired={true}
                                                            onInputChange={handleInputChange} />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className="form-group">
                                                        {/* <img src={Envelope} alt="Envelope" /> */}
                                                        <Simpleinput
                                                            id={'website'}
                                                            fieldName={'website'}
                                                            label={'Website'}
                                                            hideLabel={true}
                                                            placeHolder={"Website"}
                                                            errorText={"Website"}
                                                            register={register}
                                                            // inputValue={companyUpdate.website}
                                                            errors={errors}
                                                            isRequired={true}
                                                            onInputChange={handleInputChange}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className="form-group">
                                                        <label className='form_label'>Phone Number</label>
                                                        <Simpleinput
                                                            id={'phoneNumber'}
                                                            fieldName={'phone'}
                                                            label={'PhoneNumber'}
                                                            hideLabel={true}
                                                            placeHolder={"Phone Number"}
                                                            errorText={"Phone Number"}
                                                            register={register}
                                                            //inputValue={companyUpdate.phone}
                                                            phoneCountryCode={companyUpdate.phoneCountryCode}
                                                            errors={errors}
                                                            isRequired={true}
                                                            type={'phone'}
                                                            control={control}
                                                            onInputChange={handleInputChange} />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <h5>Representative/Authorized Signatory</h5>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <div className='form-group' >
                                                        <Simpleinput
                                                            ref={inputRef}
                                                            id={"Job title"}
                                                            fieldName={"authorizedSignatory.title"}
                                                            label={"Job Title"}
                                                            hideLabel={true}
                                                            placeHolder={"Job Title"}
                                                            errorText={"Job Title"}
                                                            register={register}
                                                            //   inputValue={companyUpdate.authorizedSignatory?.title}
                                                            errors={errors}
                                                            isRequired={true}
                                                            onInputChange={handleInputChange}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className='form-group' >
                                                        <Simpleinput
                                                            id={"First Name"}
                                                            fieldName={"authorizedSignatory.fName"}
                                                            //fieldName={companyUpdate.authorizedSignatory.fName}
                                                            label={"First name"}
                                                            hideLabel={true}
                                                            placeHolder={"First Name"}
                                                            errorText={"First Name"}
                                                            register={register}
                                                            //    inputValue={companyUpdate.authorizedSignatory.fName}
                                                            errors={errors}
                                                            //error={Boolean(errors?.[name])}
                                                            isRequired={true}
                                                            onInputChange={handleInputChange}
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className='form-group' >
                                                        <Simpleinput
                                                            id={"Last name"}
                                                            fieldName={"authorizedSignatory.lName"}
                                                            label={"Last Name"}
                                                            hideLabel={true}
                                                            placeHolder={"Last Name"}
                                                            errorText={"Last Name"}
                                                            register={register}
                                                            //  inputValue={companyUpdate.authorizedSignatory.lName}
                                                            errors={errors}
                                                            isRequired={true}
                                                            onInputChange={handleInputChange} />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className="form-group">
                                                        {/* <img src={Envelope} alt="Envelope" /> */}
                                                        <Simpleinput
                                                            id={'email'}
                                                            fieldName={'authorizedSignatory.email'}
                                                            label={'Email'}
                                                            hideLabel={true}
                                                            placeHolder={"Email Address"}
                                                            errorText={"Email Address"}
                                                            register={register}
                                                            //   inputValue={companyUpdate.authorizedSignatory.email}
                                                            errors={errors}
                                                            isRequired={true}
                                                            type={'email'}
                                                            onInputChange={handleInputChange} />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className="form-group">
                                                        {/* <img src={Phone} alt="Phone" /> */}
                                                        <label className='form_label'>Phone Number</label>
                                                        <Simpleinput
                                                            id={'phoneNumber'}
                                                            countryName={"authorizedPhone"}
                                                            fieldName={'authorizedSignatory.phone'}
                                                            label={'PhoneNumber'}
                                                            hideLabel={true}
                                                            placeHolder={"Phone Number"}
                                                            errorText={"Phone Number"}
                                                            register={register}
                                                            errors={errors}
                                                            isRequired={true}
                                                            type={'phone'}
                                                            control={control}
                                                           // inputValue={companyUpdate.authorizedSignatory.phone}
                                                            phoneCountryCode={companyUpdate.authorizedSignatory.phoneCountryCode}
                                                            onInputChange={handleInputChange} />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className="form-group">
                                                        {/* <img src={Phone} alt="Phone" /> */}
                                                        <label className='form_label'>Mobile Telephone Number</label>
                                                        <Simpleinput
                                                            id={'mobile Telephone Number'}
                                                            countryName={"authorizedHomePhone"}
                                                            fieldName={'authorizedSignatory.homePhone'}
                                                            label={'Mobile Telephone Number'}
                                                            hideLabel={true}
                                                            placeHolder={"Mobile Telephone Number"}
                                                            // errorText={"Mobile Telephone Number"}
                                                            register={register}
                                                            //errors={errors}
                                                            isRequired={false}
                                                            type={'phone'}
                                                            control={control}
                                                            //  inputValue={companyUpdate.authorizedSignatory.homePhone}
                                                            phoneCountryCode={companyUpdate.authorizedSignatory.homePhoneCountryCode}
                                                            onInputChange={handleInputChange}
                                                        />
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className='form-group' >
                                                        <label>Date format</label>
                                                        <DateTimePicker
                                                            id="browserTS"
                                                            {...register('browserTS', { required: 'Date and Time is required' })}
                                                            //onChange={onChange}
                                                            onChange={(datetime) => {
                                                                if (!isEmpty(datetime)) {
                                                                    let dateFormat = Moment(datetime).format("YYYY-MM-DDTHH:mm:ssZ")
                                                                    onChange(dateFormat);
                                                                    setValue('browserTS', dateFormat);
                                                                    errors.browserTS = false
                                                                } else {
                                                                    onChange("");
                                                                    setValue('browserTS', "");
                                                                }
                                                            }}
                                                            value={value}
                                                            format={Moment(value).format("YYYY-MM-DD HH:mm:ss")}
                                                            dayPlaceholder='DD'
                                                            hourPlaceholder='HH'
                                                            yearPlaceholder='YYYY'
                                                            minutePlaceholder='SS'
                                                            secondPlaceholder='SS'
                                                            monthPlaceholder='MM'/>
                                                        {errors.browserTS && <span className='form_error'>{errors.browserTS.message}</span>}
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className='form-group' >
                                                        <label>Time zone</label>
                                                        <TimezoneSelect
                                                            id="timezone"
                                                            {...register('timezone', { required: 'Timezone is required' })}
                                                            menuPlacement='auto'
                                                            value={selectedTimezone}
                                                            classNamePrefix="react-timezone-select"
                                                            //onChange={handleTimezoneChange}
                                                            onChange={(timezone) => {
                                                                setSelectedTimezone(timezone.value);
                                                                setValue('timezone', timezone.value);
                                                                errors.timezone = false
                                                            }}
                                                            placeholder='Asia/Kolkata'
                                                        />
                                                        {errors.timezone && <span className='form_error'>{errors.timezone.message}</span>}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>
                                    <div className="Profile-footer">
                                        <button className="primary_btn active" onClick={(event) => event.preventDefault()}>Cancel</button>
                                        <button className="primary_btn more" onClick={handleSubmit(onSubmit, onErrors)}
                                        >Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    <p>Account</p>
                                    <div className="Profile-body">
                                        <form>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className='form-group' >
                                                        <TextField id="outlined-basic" label="Account Name" hiddenLabel variant="outlined" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className='form-group' >
                                                        <label>Date Field Format</label>
                                                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} placeholderText='MM/DD/YYYY' />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6}>
                                                    <div className='form-group' >
                                                        <label>(GMT+00:00) Coordinated Universal Time</label>
                                                        <DatePicker selected={startDates} onChange={(date) => setStartDates(date)} placeholderText='MM/DD/YYYY' />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <div class="badge">Branding</div>
                                                    <div className='form-group question_switch' >
                                                        <FormControlLabel control={<Switch defaultChecked />} label="Mute ESTROKES Branding" className="switch-field" />
                                                        <img src={QuestionMark} alt="QuestionMark" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div class="badge">Signature & Initials Preferences <img src={QuestionMark} alt="QuestionMark" /></div>
                                                    <div className='form-group question_switch' >
                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControlLabel control={<Switch defaultChecked />} label="Disable Typed Signatures" className="switch-field" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControlLabel control={<Switch defaultChecked />} label="Disable Drawn Signatures" className="switch-field" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControlLabel control={<Switch defaultChecked />} label="Disable Uploaded Signatures" className="switch-field" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControlLabel control={<Switch defaultChecked />} label="Disable “Apply Everywhere”" className="switch-field" />
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="badge">Document Preferences</div>
                                                    <div className='form-group question_switch' >
                                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControlLabel control={<Switch defaultChecked />} label="Hide Document ID" className="switch-field" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <FormControlLabel control={<Switch defaultChecked />} label="Separate All Completed Document Files" className="switch-field" />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={6}>
                                                                <Select native
                                                                    className="select-option-doc"
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    label="Age"
                                                                    fullWidth
                                                                    value={answers}
                                                                    onChange={event => setAnswers(event.target.value)}
                                                                    renderValue={
                                                                        answers !== "" ? undefined : () => <Placeholder>Select</Placeholder>
                                                                    }
                                                                    displayEmpty
                                                                >
                                                                    <option value={10}>Ten</option>
                                                                    <option value={20}>Twenty</option>
                                                                    <option value={30}>Thirty</option>
                                                                </Select>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="badge">Branding</div>
                                                    <Grid item xs={12} sm={12} md={6}>
                                                        <div className="select-field">
                                                            <span>Links expire in</span>
                                                            <Select native
                                                                className="select-option-doc"
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Age"
                                                                fullWidth
                                                                value={answer}
                                                                onChange={event => setAnswer(event.target.value)}
                                                                renderValue={
                                                                    answer !== "" ? undefined : () => <Placeholder>10</Placeholder>
                                                                }
                                                                displayEmpty
                                                            >
                                                                <option value={10}>10</option>
                                                                <option value={20}>20</option>
                                                                <option value={30}>30</option>
                                                            </Select>
                                                            <span> days</span>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className="badge">Redirect To Page After Signing</div>
                                                    <div className='form-group question_switch' >
                                                        <FormControlLabel control={<Switch defaultChecked />} label="Enable" class="switch-field" />
                                                    </div>
                                                    <Grid item xs={12} sm={12} md={6}>
                                                        <div className='form-group' >
                                                            <TextField id="outlined-basic" label="" hiddenLabel variant="outlined" placeholder='https://custom.com/page' />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    <p>Notifications</p>
                                    <div className="Profile-body branding"></div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    <p>Email Branding</p>
                                    <div className="Profile-body branding">
                                        <form>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={12}>
                                                    <div className='form-group' >
                                                        <label>Logo (Email and Recipient Page)</label>
                                                        <DropzoneArea
                                                            dropzoneClass={'customdropzone'}
                                                            showPreviews={true}
                                                            dropzoneText="Drag and drop here or Browse"
                                                            Icon={AttachFile}
                                                            showPreviewsInDropzone={false}
                                                            //useChipsForPreview
                                                            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                                            // previewChipProps={{classes: { root: classes.previewChip } }}
                                                            onChange={(files) => console.log('Files:', files)}

                                                        />
                                                        <span className='drop-label'>Maximum image size: 300px by 60px. We recommend using a logo with a transparent background</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className='form-group' >
                                                        <TextField id="outlined-basic" label="Email “From” Field" hiddenLabel variant="outlined" />
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <div className='form-group ' >
                                                        <label>Email Signature</label>
                                                        <FormControlLabel control={<Switch defaultChecked />} label="Enable" className="switch-field" />
                                                        <div className='text-area'>
                                                            <p>Thanks, </p><br />
                                                            <p>new bee <br />Company</p>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    <p>Team Members</p>
                                    <div className="Profile-body branding"></div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    <p>Teams</p>
                                    <div className="Profile-body branding"></div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    <p>Integrations</p>
                                    <div className="Profile-body branding"></div>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className="panel-content">
                                <div className="profile">
                                    <p>API</p>
                                    <div className="Profile-body branding"></div>
                                </div>
                            </div>
                        </TabPanel>
                    </div>
                </Tabs>
                {isLoading &&
             <LoadingSpinner />}
            </div>
            
        </div>
    )
}
export default Settings;