

export class StorageService {


    /** 
     * To store data
    */
   set = (type, data) => {
      localStorage.setItem(type,(JSON.stringify(data)));
   }

   /**
    * To retrieve data
    */
   get = (type) => {
     // return localStorage.getItem("userinfo");
      return localStorage.getItem(type);
   }

   /**
    * To clear a specific item
    */
   remove = () => {
       localStorage.removeItem('');
   }

   /**
    * To clear the whole data stored in localStorage
    */
   clear = () => {
      localStorage.clear();
   }

}
