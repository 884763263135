import React, { useState,useEffect } from 'react'; 
import '../styles/scss/login.scss';  
import '../styles/scss/components/simpleInput.scss';
import Padlock from '../images/padlock.svg';
//import { useHistory }from 'react-router-dom';
import { EndPointService }  from '../services/endPointServices';
import { FetchService } from '../services/fetchServices';

//import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { useForm } from "react-hook-form";
import Simpleinput from './components/simpleInput';
//import Loader from '../images/loader.gif';
import {showToast,isApiLoading,isEmpty} from './Utils'
import { useHistory } from 'react-router-dom';
//import { StorageService } from '../services/storageService';
function ResetPassword  ()  {
    const [newPwd, setNewPwd] = useState("");
    const [confirmPwd,setConfirmPwd] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    //const [userData, setUserData] = useState("");
    const { register, clearErrors, handleSubmit, formState: { errors },  } = useForm();
    //Get query params
    const search = useLocation().search
    const history = useHistory();
    const searchParams = new URLSearchParams(search)
    const {  LoadingSpinner } = isApiLoading()
    // const handleClick = (e) => {
    //     e.preventDefault();
    //     console.log("handleClick")
    //     //JSON format  for payload
    //    const endpointService = new EndPointService();
    //    const fetchService = new FetchService();
    //    fetchService.post({
    //         url: endpointService.getResetPassword(),
    //         data: {
    //             "pin": searchParams? searchParams.get('key'): '',
    //             "action": "forgot-password",
    //             "newPwd": newPwd,
    //             "confirmPwd": confirmPwd,
    //             "userId": searchParams? searchParams.get('userId'): ''
    //         } 
    //     });
    // }
    useEffect(() => {
        // const storageService = new StorageService();
        // const userinfoData=JSON.parse(storageService.get("userinfo"))
        // setUserData(userinfoData)
        document.body.classList.add('login-layout');
        return () => { 
          document.body.classList.remove('login-layout');
        }
      },[]);
    const handleNewPasswordInputChange = (event) => {
        setNewPwd(event.target.value);
        clearErrors("newPwd")
    };
    const handleConfirmInputChange = (event) => {
        setConfirmPwd(event.target.value);
        clearErrors("confirmPwd")
    };
    function onSubmit(data) {
        setIsLoading(true)
       const endpointService = new EndPointService();
       const fetchService = new FetchService();
       fetchService.post({
            url: endpointService.getResetPassword(),
            data: {
                "pin": searchParams? searchParams.get('key'): '',
                "action": "set-password",
                "newPwd": newPwd,
                "confirmPwd": confirmPwd,
                "companyId":searchParams? searchParams.get('companyId'): ''
            } 
        })
        .subscribe(
            (response) => {
                setIsLoading(false)
                if (response.status === 'Success') {
                    showToast(response.message,"success")
                    setNewPwd("")
                    setConfirmPwd("")
                    history.push('/login') 
                } else {
                    showToast(response.message,"error")
                }
            },
            (error) => {
                console.error("There has been an error", error);
            }
        );

    };
    const onErrors = (errors) => {
        console.log("errors are ", errors)
    }
    return (

        <div className="login_page_wrapper"> 
            <div className="login_area">
                <h4> Reset Password</h4>
                <div className="login-form">
                    <form>
                        <div className="form-group">
                            <img src={Padlock} alt="Padlock" />
                            <Simpleinput
                                id={'NewPassword'}
                                fieldName={'newPwd'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"NewPassword"}
                                errorText={"NewPassword"}
                                register={register}
                                inputValue={newPwd}
                                errors={errors}
                                isRequired={true}
                                type={'password'}
                                onInputChange={handleNewPasswordInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <img src={Padlock} alt="Padlock" />
                            <Simpleinput
                                id={'ConfirmPassword'}
                                fieldName={'confirmPwd'}
                                label={''}
                                hideLabel={true}
                                placeHolder={"ConfirmPassword"}
                                errorText={"ConfirmPassword"}
                                register={register}
                                inputValue={confirmPwd}
                                errors={errors}
                                isRequired={true}
                                type={'password'}
                                onInputChange={handleConfirmInputChange}
                            />
                        </div>
                        <button type="button" onClick={handleSubmit(onSubmit, onErrors)} className="primary_btn login">
                            Submit
                        </button>
                        <div className="sign_up"> 
                            <p>If you want to login? <Link to="/login"> <span>Login</span></Link></p>
                        </div>
                    </form>
                </div>
            </div>
            {isLoading&&<LoadingSpinner/>}
            {/* {isApiLoading&&
            (<div>
                <span className='loader'>
                <img src={Loader} alt='Loader' />
            </span>
            </div>)}  */}
        </div>
    )
}


export default ResetPassword;
