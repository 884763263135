import React, { useState, useEffect } from 'react';
import { Route, useLocation, useHistory } from 'react-router-dom';
import Login from '../views/login';
import Signup from '../views/Signup';
import ValidateEmail from '../views/ValidateEmail';
import Dashboard from '../views/dashboard';
import SendForSignature from '../views/sendForSignature';
import Settings from '../views/settings';
import Templates from '../views/templates';
import PendingWithOthers from '../views/pendingWithOthers.js';
import Completed from '../views/Completed';
import Drafts from '../views/Drafts';
import ForgotPassword from '../views/ForgotPassword.js';
import ResetPassword from '../views/ResetPassword.js';
import PendingWithMe from '../views/pendingWithMe';
import Reports from '../views/Reports';
import PlanAndPayments from '../views/planAndPayments';
import Pending from '../views/Pending';
import DataTable from '../views/components/table2';
import Users from '../views/Users';
import Tasks from '../views/Tasks';
import Documents from '../views/Documents';
import Companies from '../views/Companies';
import Categories from '../views/Categories';
import API from '../views/APIpage';
import Contacts from '../views/Contacts';
import Mytemplates from '../views/Mytemplates';

import Components from '../views/Components';
import { StorageService } from '../services/storageService';
import ProtectedRoute from './ProtectedRoute';
import Header from '../views/Header';
import Sidenav from '../views/Sidenav';


function isLoggedIn() {
    const storageService = new StorageService();
    return storageService.get("isLoggedIn")
}
function isAuthRoutes(path) {
    ///alert(path)
    return ['/login', '/public/email-validate', '/signup', '/public/reset-password', '/forgotpassword'].findIndex((item) => item == path) > -1
}



function Router() {
    const location = useLocation();
    const [currPath, setCurrPath] = useState(window.location.pathname)
    const history = useHistory();

    useEffect(() => {
        if (isLoggedIn() && isAuthRoutes(window.location.pathname)) {
            if (window.location.pathname == '/public/reset-password') {
                localStorage.clear()
            } else {
                //history.replace('/');
            }

        }
        if (!isLoggedIn() && !isAuthRoutes(window.location.pathname)) {
            history.replace('/login');
        }
        setCurrPath(window.location.pathname);
        //  alert(window.location.pathname)
    }, [location]);


    return (

        <React.Fragment>

            <div className='wrapper'>
                {!isAuthRoutes(currPath) && <Header />}
                <div className='bodylayout'>
                    {/*  {currPath !== '/login' && <><Sidenav /></>}*/}
                    {!isAuthRoutes(currPath) && <Sidenav />}
                    <div className='maincontent'>
                        <Route exact path="/login" >
                            <Login />
                        </Route>
                        <Route exact path="/forgotpassword" >
                            <ForgotPassword />
                        </Route>
                        <Route exact path="/public/reset-password">
                            <ResetPassword />
                        </Route>
                        <Route exact path="/signup" >
                            <Signup />
                        </Route>
                        <Route exact path="/public/email-validate" >
                            <ValidateEmail />
                        </Route>

                        <Route exact path="/" >
                            <ProtectedRoute
                                path="/"
                                component={Dashboard}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Dashboard /> */}
                        </Route>
                        <Route exact path="/send-for-signature" >
                            <ProtectedRoute
                                path="/send-for-signature"
                                component={SendForSignature}
                                isAuthenticated={isLoggedIn()}
                            />
                        </Route>
                        <Route exact path="/settings" >
                            <ProtectedRoute
                                path="/settings"
                                component={Settings}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Settings /> */}
                        </Route>
                        <Route exact path="/templates" >
                            <ProtectedRoute
                                path="/templates"
                                component={Templates}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Templates /> */}
                        </Route>
                        <Route exact path="/pending-with-others" >
                            <ProtectedRoute
                                path="/pending-with-others"
                                component={PendingWithOthers}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <PendingWithOthers /> */}
                        </Route>
                        <Route exact path="/Pending" >
                            <ProtectedRoute
                                path="/Pending"
                                component={Pending}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Pending /> */}
                        </Route>
                        <Route path='/documents'>
                            <ProtectedRoute
                                path="/documents"
                                component={Documents}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Documents /> */}
                        </Route>
                        <Route path='/companies'>
                            <ProtectedRoute
                                path="/companies"
                                component={Companies}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Companies /> */}
                        </Route>
                        <Route path='/categories'>
                            <ProtectedRoute
                                path="/categories"
                                component={Categories}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Categories /> */}
                        </Route>
                        <Route path='/api'>
                            <ProtectedRoute
                                path="/api"
                                component={API}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <API /> */}
                        </Route>
                        <Route path='/pending-with-me'>
                            <ProtectedRoute
                                path="/pending-with-me"
                                component={PendingWithMe}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <PendingWithMe /> */}
                        </Route>
                        <Route exact path="/completed" >
                            <ProtectedRoute
                                path="/completed"
                                component={Completed}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Completed /> */}
                        </Route>
                        <Route exact path="/drafts" >
                            <ProtectedRoute
                                path="/drafts"
                                component={Drafts}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Drafts /> */}
                        </Route>
                        <Route path='/reports'>
                            <ProtectedRoute
                                path="/reports"
                                component={Reports}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Reports /> */}
                        </Route>
                        <Route path='/planAndPayments'>
                            <ProtectedRoute
                                path="/planAndPayments"
                                component={PlanAndPayments}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <PlanAndPayments /> */}
                        </Route>
                        <Route path='/users'  >
                            <ProtectedRoute
                                path="/users"
                                component={Users}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Users /> */}
                        </Route>
                        <Route path='/tasks'  >
                            <ProtectedRoute
                                path="/tasks"
                                component={Tasks}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Tasks /> */}
                        </Route>
                        <Route path='/contacts'  >
                            <ProtectedRoute
                                path="/contacts"
                                component={Contacts}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Contacts /> */}
                        </Route>
                        <Route path='/basic-components'  >
                            <ProtectedRoute
                                path="/basic-components"
                                component={Components}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Components /> */}
                        </Route>
                        <Route path='/mytemplates'  >
                            <ProtectedRoute
                                path="/mytemplates"
                                component={Mytemplates}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <Mytemplates /> */}
                        </Route>
                        <Route path='/table2'>
                            <ProtectedRoute
                                path="/table2"
                                component={DataTable}
                                isAuthenticated={isLoggedIn()}
                            />
                            {/* <DataTable /> */}
                        </Route>
                    </div>
                </div>
            </div>


        </React.Fragment>
    )
}

export default Router;